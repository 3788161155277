import React, {ChangeEvent, MouseEvent} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Trans} from 'react-i18next';
import CatalogCodesMultiSelect from './CatalogCodesMultiSelect'
import Companies, {CatalogCodeDto, CompanyDto} from './Companies'


export interface ExistingCompanyProps {
  company: CompanyDto,
  handleDelete: (e: MouseEvent) => void,
  handleUpdate: (e: MouseEvent) => void,
  handleName: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSuissetecMember: (e: ChangeEvent<HTMLInputElement>) => void,
  catalogCodes: CatalogCodeDto[],
  handleAssemblyHourlyRate: (e: ChangeEvent<HTMLInputElement>) => void,
  handleInternalAssemblyHourlyRate: (e: ChangeEvent<HTMLInputElement>) => void,
  handleExternalId: (e: ChangeEvent<HTMLInputElement>) => void,
  handleReseller: (e: ChangeEvent<HTMLInputElement>) => void,
}

const ExistingCompany = ({
                           company,
                           handleDelete,
                           handleUpdate,
                           handleName,
                           handleSuissetecMember,
                           catalogCodes,
                           handleAssemblyHourlyRate,
                           handleInternalAssemblyHourlyRate,
                           handleExternalId,
                           handleReseller
                         }: ExistingCompanyProps) => {

  const displayCatalogCodes = (company: CompanyDto) => {
    return <CatalogCodesMultiSelect catalogCodes={catalogCodes}
                                    defaultValues={company.catalogCodes ? company.catalogCodes : []}
                                    status={company.status} company={company} />
  }

  const handleRestore = (event: MouseEvent, company: CompanyDto) => {
    company.status = "ACTIVE";
    handleUpdate(event);
  }

  return (

      <Row className="tableContent">
        <Col xs={2} className="value">
          {company.status === 'ACTIVE' ?
              <input type='text'
                     value={company.name}
                     onChange={handleName}/>
              :
              <input type='text'
                     value={company.name}
                     onChange={handleName} disabled/>
          }
        </Col>
        <Col xs={1} className="value">
          {<input type='text'
                  value={company.code}
                  className='w-100'
                  disabled/>
          }
        </Col>
        <Col xs={2} className="value">
          {displayCatalogCodes(company)}
        </Col>
        <Col xs={1} className="value">
          {company.status === 'ACTIVE' ?
              <input type='text'
                     value={company.assemblyHourlyRate}
                     className='w-100'
                     onChange={handleAssemblyHourlyRate}/>
              :
              <input type='text'
                     value={company.assemblyHourlyRate}
                     className='w-100'
                     onChange={handleAssemblyHourlyRate} disabled/>
          }
        </Col>
        <Col xs={1} className="value">
          {company.status === 'ACTIVE' ?
              <input type='text'
                     value={company.internalAssemblyHourlyRate}
                     className='w-100'
                     onChange={handleInternalAssemblyHourlyRate}/>
              :
              <input type='text'
                     value={company.internalAssemblyHourlyRate}
                     className='w-100'
                     onChange={handleInternalAssemblyHourlyRate} disabled/>
          }
        </Col>

        <Col xs={1} className="value">
          <div>
            {company.status === 'ACTIVE' ?
                <input type="checkbox" checked={company.suissetecMember}
                       onChange={handleSuissetecMember}/>
                :
                <input type="checkbox" checked={company.suissetecMember}
                       disabled={true}/>
            }
          </div>
        </Col>

        <Col xs={1} className="value">
          <input type='text'
                 value={company.externalId}
                 className='w-100'
                 onChange={handleExternalId}
                 disabled={company.status !== 'ACTIVE'}
          />
        </Col>

        <Col xs={1} className="value">
          <div>
            <input type="checkbox" checked={company.reseller}
                   onChange={handleReseller} disabled={company.status !== 'ACTIVE'}/>
          </div>
        </Col>

        <Col xs={1} className="value">
          {company.status === 'ACTIVE' ?
              <Button className="function-button" onClick={(e) => {
                handleUpdate(e)
              }}>
                <img src="/company-icons/arrow-repeat.svg"
                     style={{width: 12, height: 12}} alt='update'/>
                <p><Trans
                    i18nKey={'company.existingCompany.existingCompany.update'}/></p>
              </Button> :
              <Button className="function-button" onClick={(e) => {
                handleUpdate(e)
              }} disabled>
                <img src="/company-icons/arrow-repeat.svg"
                     style={{width: 12, height: 12}} alt='update'/>
                <p><Trans
                    i18nKey={'company.existingCompany.existingCompany.update'}/></p>
              </Button>
          }
        </Col>
        <Col xs={2} xl={1} className="value">
          {company.status === 'ACTIVE' ?
              <Button className="function-button" onClick={(e) => {
                handleDelete(e)
              }}>
                <img src="/company-icons/x-circle.svg"
                     style={{width: 12, height: 12}} alt='delete'/>
                <p><Trans
                    i18nKey={'company.existingCompany.existingCompany.delete'}/></p>
              </Button> :
              <Button className="function-button" onClick={(e) => {
                handleRestore(e, company)
              }} >
                <img src="/company-icons/x-circle.svg"
                     style={{width: 12, height: 12}} alt='delete'/>
                <p><Trans
                    i18nKey={'company.existingCompany.existingCompany.restore'}/></p>
              </Button>
          }
        </Col>
      </Row>
  );
};

export default ExistingCompany;
