import React from 'react';
import {FormText, Image, Row, Col} from "react-bootstrap";
import {Trans} from 'react-i18next';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import  {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Token} from "../types/token";
import conf from "../config";
import util from "../util";
import {AxiosResponse} from "axios";
import {MultiSelectModel} from "../company-overview/CatalogCodesMultiSelect";
import {CatalogCodeDto} from "../company-overview/Companies";

const Body = () => {
 let token: any;
 const id_token: Token = useSelector((state: any) => state.addAuthData);
 token = id_token?id_token.idToken : undefined;

useEffect(() => {
        const sdk = new ChartsEmbedSDK({
                     baseUrl: "https://charts.mongodb.com/charts-platform-oalix",
                     getUserToken: () => token,
                     maxDataAge: 10,
                     height: 350
                   });

           const chart = sdk.createChart({
             chartId: '6212b9ce-ee96-4661-86dc-787bdbe04629',
           });

        const chartDiv = document.getElementById('chart')!;
        chart.render(chartDiv)
     });

    return (
        <div className="mainViewBody">
          <Row>

            <Col md={3}>
              <FormText className="heading">
                <Trans
                    i18nKey="main.body.mainViewBody.headingText" // optional -> fallbacks to defaults if not provided
                    defaults="Home - Dashboard" // optional defaultValue
                    components={{tag: <span/>}}
                />

               </FormText>
            </Col>

          </Row>

          <Row>
            <Col md={1}>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/total-views.png" fluid/>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/total-sales.png" fluid/>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/total-earnings.png" fluid/>
            </Col>
            <Col md={1}>
            </Col>
          </Row>

        <Row>
            <Col md={1}>
            </Col>
            <Col md={9}>
              <div id = "chart" className = "container-fluid" ></div>
            </Col>
            <Col md={1}>
            </Col>
          </Row>

          <Row >
            <Col md={1}>
            </Col>
            <Col md={9}>
              <Image src="/dashboard-icons/statistics.png" fluid width={'100%'} />
            </Col>
            <Col md={1}>
            </Col>
          </Row>

          <Row>
            <Col md={1}>
            </Col>
            <Col md={6}>
              <Image src="/dashboard-icons/referrer.png" fluid/>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/details-on-map.png" fluid/>
            </Col>
            <Col md={2}>
            </Col>
          </Row>

          <Row>
            <Col md={1}>
            </Col>
            <Col md={6}>
              <Image src="/dashboard-icons/top-product.png" fluid/>
            </Col>
            <Col md={3}>
              <Image src="/dashboard-icons/quick-details.png" fluid/>
            </Col>
            <Col md={1}>
            </Col>
          </Row>

        </div>
    );
}

export default Body;