import React, {useEffect, useState, useCallback} from 'react';
import {
  Popover,
  Spinner,
  Row,
  Col
} from 'react-bootstrap';
import Header from './Header'
import VerticalMenu from "./VerticalMenu";
import Body from "./Body";
import conf from '../config';
import {useDispatch} from "react-redux";
import {setCurrentAuthData} from "../actions/authData";
import {HashRouter as Router, Route} from "react-router-dom";
import {useAuth} from 'oidc-react';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import OrderOverview from "../order-overview/OrderOverview";
import CustomerChanges from "../customer-changes/CustomerChanges";
import CustomerChangeAnalysis from "../customer-changes-analysis/CustomerChangeAnalysis";
import Companies from "../company-overview/Companies";

interface UserInfoDetails {
  c_hash: string;
  family_name: string;
  gender: string;
  given_name: string;
  locale: string;
  name: string;
  preferred_username: string;
  sub: string;
  updated_at: number;
  idTokenClaims: any;
  "urn:zitadel:iam:org:project:roles": Array<any>;
}

const MainPage = () => {

  const { t } = useTranslation();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [isAdminOrder, setIsAdminOrder] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(false);
  const auth = useAuth();
  const dispatch = useDispatch();
  var userInfo = undefined as any;

  const checkUser = useCallback(async () => {
    if (await auth && await auth?.userData) {
       userInfo  = auth?.userData?.profile;
      const idToken = auth?.userData?.id_token;
      const accessToken = auth?.userData?.access_token;
      let isAdminOrder = false;
      setAuthenticated(true);

      const userInfoObject: UserInfoDetails = userInfo as UserInfoDetails;
      var userPermissions = userInfoObject["urn:zitadel:iam:org:project:roles"][0];

      if (userPermissions && userPermissions.hasOwnProperty(
          conf.permissions.admin)) {
        isAdminOrder = true;
      } else {
        if (userPermissions && userPermissions.hasOwnProperty(
            conf.permissions.admin_order)) {
          isAdminOrder = true;
        }
      }
      setIsAdminOrder(isAdminOrder);
      dispatch(setCurrentAuthData({userInfo, idToken, accessToken, authenticated: true,
        isAdminOrder}));
    } else {
      dispatch(setCurrentAuthData({userInfo: null, idToken: null, accessToken: null, authenticated: false,
          isAdminOrder: false}));
    }
  }, [auth]);

  useEffect(() => {
    document.title  = t('main.mainPage.mainPageTitle');
     checkUser();
  }, [checkUser]);

  const logout = () => {
    auth?.signOut();
  };

  const popover = (
      <Popover id="popover-main">
        <Popover.Header as="h3" className="popover-basic"><>{t('main.mainPage.popover-main.title')}</></Popover.Header>
        <Popover.Body className="popover-basic">
          <>{t('main.mainPage.popover-main.popoverText')}</>
        </Popover.Body>
      </Popover>
  );

  const toggleCollapse = () => {
    setCollapse(!collapse);
  }

  return (
      <Router>
        <div className="backofficeContent">

          <Header logout={logout}/>

          {authenticated ? (
                  (isAdminOrder) ?
                      (
                          <div className="mainPage" text-center="true">

                            <Row>
                              <Col md={2}
                                   className={collapse ? "verticalMenu collapsed"
                                       : "verticalMenu"}>
                                <VerticalMenu toggleCollapse={toggleCollapse}/>
                              </Col>
                              <Col md={10}>
                                <Route exact path="/" component={Body}/>
                                <Route exact path="/order-overview" component={OrderOverview}/>
                                <Route exact path="/company-overview" component={Companies}/>
                                <Route exact path="/customer-changes/:version" component={CustomerChanges}/>
                                <Route exact path="/customer-changes/:version/customer-change-analysis/:id" component={CustomerChangeAnalysis}/>
                              </Col>
                            </Row>
                          </div>
                      )
                      :
                      <div className='messageLogout'>
                        <>{t('main.mainPage.messageLogout.messageText')}</>
                      </div>
              )
              :
              <div className='spinner'>
                <Spinner animation="border" role="status" size="sm"/>{' '}Authenticating
              </div>
          }
        </div>
        <ToastContainer/>
      </Router>
  );
}

export default MainPage;