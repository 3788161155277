import React, {useEffect, useState, useCallback} from "react";
import {useSelector} from "react-redux";
import conf from "../config";
import {
  Container,
  Row,
  Col,
  Pagination,
  Spinner, Button,
} from 'react-bootstrap';
import util from "../util";
import {useTranslation} from 'react-i18next';
import {Trans} from 'react-i18next';
import {Token} from "../types/token";
import {AxiosResponse} from "axios";
import {CustomerChange, ExtendedCustomerChange, Synonym} from "../generated/api/ineexaApi";
import {useHistory, useParams} from "react-router-dom";
import ProductComparison from "./ProductComparison";
import ParsedData from "./ParsedData";
import MatchingSynonym from "./MatchingSynonym";

const CustomerChangeAnalysis = () => {

  const {t} = useTranslation();
  const history = useHistory();
  const [extendedCustomerChange, setExtendedCustomerChange] = useState<ExtendedCustomerChange | undefined>(undefined);
  const [synonyms, setSynonyms] = useState<Synonym[] | undefined>(undefined);
  const [totalNumberCustomerChanges, setTotalNumberCustomerChanges] = useState(0);
  const [customerChangesIds, setCustomerChangesIds] = useState<string[]>([])
  const [currentCustomerChangeIndex, setCurrentCustomerChangeIndex] = useState(0)
  let { version, id } : any = useParams();
  const token: Token = useSelector((state: any) => state.addAuthData);

  const updateCustomerChangeAnalysisElement = useCallback(() => {
    const url = `${conf.urls.serviceUrl}${conf.urls.orderOverviewAPI}/${version}/customerchange/${id}`;
    util.serviceCallWrapper({
          method: "GET",
          url: url,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,

          }
        },
        (result: AxiosResponse) => {
          if (result.data !== '' && result.data !== undefined && result.data.customerChange !== undefined) {
            setExtendedCustomerChange(result.data);
          }
        },
        {},
        () => {
        },
        false
    );
  }, [token, currentCustomerChangeIndex]);

  const fetchAllCustomerChangesIds = useCallback(() => {
    const url = `${conf.urls.serviceUrl}${conf.urls.orderOverviewAPI}/${version}/customerchange?customerChangeType=MANUAL&sort=pageIndex,asc&sort=productGroupIndex,asc&size=1000`;
    util.serviceCallWrapper({
          method: "GET",
          url: url,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,

          }
        },
        (result: AxiosResponse) => {
          if (result.data !== '' && result.data !== undefined && result.data.content !== undefined) {
            let customerChangesIds: string[] = result.data.content.map((customerChangeIn: CustomerChange) => customerChangeIn.id);
            setTotalNumberCustomerChanges(result.data.totalElements);
            setCustomerChangesIds(customerChangesIds);
            setCurrentCustomerChangeIndex(customerChangesIds.indexOf(id));
          }
        },
        {},
        () => {
        },
        false
    );
  }, [token, currentCustomerChangeIndex]);



  const retrieveSynonyms = useCallback(() => {
    const url = `${conf.urls.serviceUrl}${conf.urls.synonymOverviewAPI}/analyze`;
    const data = `${extendedCustomerChange?.productGroup.name} ${extendedCustomerChange?.product.originalText}`.replaceAll("\n", " ")
    util.serviceCallWrapper({
          method: "POST",
          url: url,
          data: {input: data},
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          if (result.data !== '' && result.data !== undefined) {
            setSynonyms(result.data);
          }
        },
        {},
        () => {
        },
        false
    );
  }, [token, extendedCustomerChange]);

  const redirectToCustomerChanges = () => {
    history.push(`${conf.urls.backoffice_customer_changes}/${version}`);
  };

  const markCustomerChangeAsDone = () => {
    const url = `${conf.urls.serviceUrl}${conf.urls.orderOverviewAPI}/${version}/customerchange/${id}`;
    util.serviceCallWrapper({
          method: "PUT",
          url: url,
          data: {
            "reviewed": true
          },
          headers: {
            Authorization: `Bearer ${token.accessToken}`,

          }
        },
        (result: AxiosResponse) => {
          //uncomment when the API correctly returns updated CustomerChange
          // if (result.data !== '' && result.data !== undefined) {
          //   setExtendedCustomerChange(result.data);
          // }
        },
        {},
        () => {
        },
        false
    );
  };

  useEffect(() => {
    document.title = t('customerChangeAnalysis.mainTitle');
    updateCustomerChangeAnalysisElement();
    fetchAllCustomerChangesIds()
  }, [currentCustomerChangeIndex]);

  useEffect(() => {
    retrieveSynonyms();
  }, [extendedCustomerChange]);

  const openNextCustomerChange = () => {
    if (currentCustomerChangeIndex + 1 < totalNumberCustomerChanges) {
      setCurrentCustomerChangeIndex(currentCustomerChangeIndex + 1);
      history.push(`${history.location.pathname.replace(`/${id}`, `/${customerChangesIds[currentCustomerChangeIndex + 1]}`)}`);
    }
  };

  const openPreviousCustomerChange = () => {
    if (currentCustomerChangeIndex > 0) {
      setCurrentCustomerChangeIndex(currentCustomerChangeIndex - 1);
      history.push(`${history.location.pathname.replace(`/${id}`, `/${customerChangesIds[currentCustomerChangeIndex - 1]}`)}`);
    }
  };

  const renderSynonym = (synonym: Synonym) => {
    return (<MatchingSynonym key={synonym.id} synonym={synonym}/>);
  };

  return (
      <div>
        <Row>
          <Col xl={1} className="hidden-lg"/>
          <Col xl={10} className="mainContent">
            <Container fluid className="orders">
              {(token.authenticated) ? (
                      (token.isAdminOrder) ?

                          <div>
                            <Row className="pageName">
                              <Col xs={{span: 12}} className="label">
                                <Trans
                                    i18nKey="customerChangeAnalysis.mainView.label" // optional -> fallbacks to defaults if not provided
                                    defaults="Customer Change Analysis -  Analysis" // optional defaultValue
                                    components={{tag: <span/>}}
                                />
                              </Col>
                            </Row>

                            <ProductComparison key={extendedCustomerChange?.customerChange.id} extendedCustomerChange={extendedCustomerChange}/>

                            <br/>

                            <Row className="tableContent">
                              <Col xs={1} className="value left">
                                <>{t('customerChangeAnalysis.tableContent.userQuery.label')}</>
                              </Col>
                              <Col xs={11} className="value">
                                {extendedCustomerChange?.searchQuery?.title}
                              </Col>
                            </Row>

                             <br/>

                            <ParsedData key={Math.random()} extendedCustomerChange={extendedCustomerChange}/>

                            <br/>

                            <Row className="tableHeader">
                              <Col xs={12} className="label left">
                                <>{t('customerChangeAnalysis.tableHeader.matchingSynonyms.label')}</>
                              </Col>
                            </Row>
                            {synonyms?.map(synonym => renderSynonym(synonym))}

                            <br/>

                            <Row className="tableContent">
                              <Col xs={4} className="empty label"/>
                              <Col xs={3} className="value">
                                <div className="tableContent value">
                                  <Pagination.Prev className="function-button" onClick={openPreviousCustomerChange}/>
                                  <Button className="function-button">Customer Change {currentCustomerChangeIndex + 1} / {totalNumberCustomerChanges}</Button>
                                  <Pagination.Next className="function-button" onClick={openNextCustomerChange}/>
                                </div>
                              </Col>
                              <Col xs={3} className="value">
                                <Button className="function-button" onClick={redirectToCustomerChanges}>
                                      <p> <>{t('customerChangeAnalysis.tableContent.navigation.backToCustomerChanges.label')}</>  </p>
                                </Button>
                              </Col>
                              <Col xs={2} className="value">
                                <Button className="function-button" onClick={markCustomerChangeAsDone}>
                                      <p> <>{t('customerChangeAnalysis.tableContent.navigation.markAsDone.label')}</>  </p>
                                </Button>
                              </Col>
                            </Row>

                            <br/>
                          </div>
                          :
                          <div className='messageLogout'>

                            <>{t('order.ordersMain.messageLogout.messageText')}</>

                          </div>)
                  :
                  <div className='spinner'>
                    <Spinner animation="border" role="status" size="sm"/>{" "}Authenticating
                  </div>
              }

            </Container>
          </Col>
          <Col xl={1} className="hidden-lg"/>
        </Row>
      </div>
  );

};

export default CustomerChangeAnalysis;