/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface HierarchyLevelReferenceDto {
  /** The ID of the referenced hierarchy level */
  hierarchyLevelId: string;

  /**
   * The sorting index of a product group within the referenced hierarchy level
   * @format int32
   */
  index?: number;
}

export interface UpdateProductGroupDto {
  id: string;
  name?: string;
  hierarchyLevelReferences: HierarchyLevelReferenceDto[];
  manufacturer?: string;
}

export enum AssemblyTimeType {
  DEFAULT = "DEFAULT",
  COMPANY = "COMPANY",
  SUISSETEC = "SUISSETEC",
}

export enum DiameterNominalEnum {
  DN8 = "DN_8",
  DN10 = "DN_10",
  DN12 = "DN_12",
  DN15 = "DN_15",
  DN20 = "DN_20",
  DN25 = "DN_25",
  DN32 = "DN_32",
  DN40 = "DN_40",
  DN50 = "DN_50",
  DN65 = "DN_65",
  DN80 = "DN_80",
  DN100 = "DN_100",
  DN125 = "DN_125",
  DN150 = "DN_150",
  DN200 = "DN_200",
  DN250 = "DN_250",
  DN300 = "DN_300",
  DN350 = "DN_350",
  DN400 = "DN_400",
  DN450 = "DN_450",
  DN500 = "DN_500",
  DN600 = "DN_600",
  DN700 = "DN_700",
  DN800 = "DN_800",
  DN900 = "DN_900",
  DN1000 = "DN_1000",
  DN1200 = "DN_1200",
}

export interface Position {
  left: number;
  top: number;
  width: number;
  height: number;
}

export interface Product {
  id: string;
  code: string;

  /** A list of IDs referencing hierarchy levels. E.g. this is used for NPK hierarchies where a product is also assigned to a certain NPK. */
  hierarchyLevelIds?: string[];
  unit?: string;
  quantity?: number;
  type?: string;
  assemblyTime?: number;
  assemblyTimeId?: string;
  assemblyTimeType?: AssemblyTimeType;
  catalogName?: string;
  catalogArticleNumber?: string;
  diameterNominals?: DiameterNominalEnum[];
  unitGrossPrice?: number;
  unitNetPrice?: number;
  originalText?: string;
  position?: Position;
}

export interface ProductGroup {
  id: string;
  code: string;
  products?: Product[];
  name?: string;
  hierarchyLevelReferences?: HierarchyLevelReferenceDto[];
  manufacturer?: string;
  position?: Position;
}

export interface UpdateHierarchyLevel {
  name: string;

  /**
   * @min 0
   * @max 1
   */
  netPriceExtraCharge?: number;
  exclude?: boolean;
}

export interface BackendError {
  /** @format date-time */
  timestamp: string;

  /** @format int32 */
  status: number;
  error: string;
  exception?: string;
  message?: string;
  trace?: string;
  errors?: string;
  path: string;
}

export interface HierarchyLevel {
  id: string;
  type: HierarchyType;
  code: string;

  /**
   * Generally a page has no name, a BKP has it.
   * @example 242.1
   */
  name?: string;

  /** HierarchyLevels below the current one in the tree. */
  children?: HierarchyLevel[];

  /**
   * the percentage extra charge which is added to the net price
   * @example 0.15
   */
  netPriceExtraCharge?: number;

  /** true means that the reseller / installer will not offer this price position */
  exclude: boolean;
}

export enum HierarchyType {
  BKP = "BKP",
  NPK = "NPK",
  EKG = "EKG",
  KAG = "KAG",
  PAGE = "PAGE",
}

export interface Column {
  columnType: ColumnType;
  displayed: boolean;
}

export enum ColumnType {
  TYPE = "TYPE",
  UNIT = "UNIT",
  QUANTITY = "QUANTITY",
  IMAGE = "IMAGE",
  ARTICLE_NUMBER = "ARTICLE_NUMBER",
  CATALOG_NAME = "CATALOG_NAME",
  DIMENSION = "DIMENSION",
  ASSEMBLY_TIME = "ASSEMBLY_TIME",
  UNIT_NET_PRICE = "UNIT_NET_PRICE",
  TOTAL_NET_PRICE = "TOTAL_NET_PRICE",
  UNIT_GROS_PRICE = "UNIT_GROS_PRICE",
  TOTAL_GROS_PRICE = "TOTAL_GROS_PRICE",
}

export interface Layout {
  orderedColumns?: Column[];
  userId?: string;
}

/**
 * Data that will be updated on the CustomerChange
 */
export interface CustomerUpdateChange {
  reviewed: boolean;
}

export interface CatalogProduct {
  id: string;
  title: string;
  description?: string;
  catalogName: string;
  catalogArticleNumber: string;
}

export interface CustomerChange {
  id: string;
  oldProduct?: CatalogProduct;
  newProduct?: CatalogProduct;
  orderId: string;
  versionId: string;
  pageId: string;

  /** @format int32 */
  pageIndex: number;

  /** @format int32 */
  productGroupIndex: number;
  productGroupId: string;

  /** @format int32 */
  productIndex: number;
  reason: CustomerChangeReason;
  customerId: string;
  productId: string;
  question2AnswerMap?: Record<string, string>;
  customerChangeType: CustomerChangeType;

  /** @format date-time */
  createdDate: string;

  /** @format date-time */
  lastParsingDate: string;
  reviewed: boolean;
}

export enum CustomerChangeReason {
  PRODUCT_CHANGED = "PRODUCT_CHANGED",
  PRODUCT_ADDED = "PRODUCT_ADDED",
  PRODUCT_REMOVED = "PRODUCT_REMOVED",
  PRODUCT_MANUAL = "PRODUCT_MANUAL",
}

export enum CustomerChangeType {
  MANUAL = "MANUAL",
  PRESELECTION = "PRESELECTION",
}

export enum MappingType {
  Equivalent = "equivalent",
  Explicit = "explicit",
}

export interface Synonym {
  id: string;
  mappingType: MappingType;
  input?: string[];
  synonyms: string[];
}

export interface ImportRun {
  /** The id of the ImportRun */
  id: string;

  /** The email address of the user that launched the ImportRun */
  customerEmailAddress?: string;

  /** The code of the company the ImportRun refers to */
  companyCode: string;

  /** The code of the catalog the ImportRun refers to */
  catalogCode?: string;

  /** The name of the file that was uploaded in the ImportRun */
  fileName?: string;

  /** The type of the ImportRun */
  importType: ImportType;

  /**
   * The time the ImportRun started
   * @format date-time
   */
  startTime: string;

  /**
   * The time the ImportRun ended
   * @format date-time
   */
  endTime?: string;

  /** The status of the ImportRun */
  importStatus: ImportStatus;

  /** The message generated by the ImportRun */
  importStatusMessage?: string;
}

/**
 * The status of the ImportRun
 */
export enum ImportStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
}

/**
 * The type of the ImportRun
 */
export enum ImportType {
  IKK = "IKK",
  IGH451 = "IGH_451",
}

/**
 * DTO containing the new password
 */
export interface UpdatePassword {
  /** The new password */
  password: string;
}

/**
 * The credential to create
 */
export interface Credential {
  credentialType?: CredentialType;
  catalogCode?: string;
  emailAddress: string;
  password: string;
}

export enum CredentialType {
  INEEXA = "INEEXA",
  IGH451 = "IGH451",
}

export interface Token {
  /**
   * The authentication token
   * @example 5e2045b8-af53-43f5-b676-ecfee999c6b7
   */
  id: string;

  /**
   * The ID of the user
   * @example 602a7c861f7ce2674ab7be0c
   */
  userId: string;

  /**
   * The expiration date of the token
   * @format int32
   * @example 1623249412566
   */
  expiration: number;

  /**
   * The name of the roles the user is assigned to
   * @example ROLE_INSTALLER
   */
  roles?: string[];
}

export interface CreateOrder {
  /** The name of the new Order */
  name: string;
}

export interface Order {
  /** The id of the Order */
  id: string;

  /** The name of the Order */
  name: string;

  /** The trackingCode of the Order */
  trackingCode?: string;

  /** First-level version of the current Order. Versions' children are not populated */
  versions?: Version[];

  /**
   * The time the Order was created
   * @format date-time
   */
  created: string;

  /**
   * The time of the Order was last modified
   * @format date-time
   */
  modified: string;

  /** The status of the Order */
  status: OrderStatus;
}

/**
 * The status of the Order
 */
export enum OrderStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

/**
 * First-level version of the current Order. Versions' children are not populated
 */
export interface Version {
  /** The id of the version */
  id: string;

  /**
   * The time the Version was created
   * @format date-time
   */
  created: string;

  /**
   * The time the Version was last modified
   * @format date-time
   */
  modified: string;

  /** The name of the version */
  name?: string;

  /** The status of the version */
  status: VersionStatus;

  /** The id of the user that created the version */
  userId: string;

  /** The code of the company of the user that created the version */
  companyCode: string;

  /** The id of the parent Order */
  orderId: string;

  /** The id of the parent Version */
  parentId?: string;

  /** First-level children of the current Version. A child's children are not populated */
  children?: Version[];
}

/**
 * The status of the version
 */
export enum VersionStatus {
  INITIAL = "INITIAL",
  SAVED = "SAVED",
  PARSING = "PARSING",
  MANUAL_PARSING = "MANUAL_PARSING",
  READY = "READY",
  CLOSED = "CLOSED",
  ERROR = "ERROR",
}

export interface QuoteRequest {
  /** List of email addresses of recipients of the email */
  recipients: string[];

  /**
   * The subject of the email
   * @example products quote
   */
  subject: string;

  /**
   * The text of the email
   * @example Please give me a quote for these products
   */
  text: string;
}

export interface CloneVersion {
  /** The name of the cloned Version */
  name: string;
}

/**
 * The Preselections to apply
 */
export interface PreselectionDefinition {
  scope: Scope;

  /**
   * @format int32
   * @min 1
   */
  currentPage: number;

  /** @format int32 */
  toPage?: number;
  productGroupSearchConditions: Record<string, SearchCondition[]>;
}

export enum Scope {
  CURRENT_PAGE = "CURRENT_PAGE",
  CURRENT_BKP = "CURRENT_BKP",
  PAGE_RANGE = "PAGE_RANGE",
}

export interface SearchCondition {
  field: string;
  value: string;
}

export interface CreateProductGroupDto {
  name?: string;
  manufacturer?: string;
}

export interface CreateProduct {
  /** A list of IDs referencing hierarchy levels. E.g. this is used for NPK hierarchies where a product is also assigned to a certain NPK. */
  hierarchyLevelIds?: string[];
  unit?: string;
  quantity?: number;
  type?: string;
  assemblyTime?: number;
  assemblyTimeId?: string;
  assemblyTimeType?: AssemblyTimeType;
  catalogName?: string;
  catalogArticleNumber?: string;
  unitGrossPrice?: number;
  unitNetPrice?: number;
}

export interface CreateHierarchyLevel {
  type: HierarchyType;
  code: string;
  name?: string;

  /** The new HierarchyLevel will be assigned to the children of the HierarchyLevel with this parameter as id. When missing, the new HierarchyLevel will be at root level in the Hierarchy */
  parentHierarchyLevelId?: string;

  /** The new HierarchyLevel will be assigned as sibling of the HierarchyLevel with this parameter as id, right after it. When missing, the new HierarchyLevel will be the last */
  previousHierarchyLevelId?: string;

  /** The new HierarchyLevel will be assigned as sibling of the HierarchyLevel with this parameter as id, right after it. When missing, the new HierarchyLevel will be the first */
  nextHierarchyLevelId?: string;
}

export interface Feedback {
  id?: string;
  orderId?: string;
  versionId?: string;
  pageId?: string;
  productGroupId?: string;
  productId?: string;
  feedbackTypeId: string;
  message: string;
  userId?: string;

  /** @format date-time */
  createdDate?: string;
}

export enum CatalogFormat {
  IGH = "IGH",
  SPAETER = "SPAETER",
}

export interface UpdateOrderDto {
  name: string;
}

export interface UpdateVersionDto {
  name: string;
}

export interface FeedbackQuestionReply {
  questionCode?: string;
  answerCode?: string;
}

export interface User {
  /** The id of the User */
  id: string;

  /** The email address of the User */
  emailAddress: string;

  /** The code of the company the user belongs to */
  companyCode: string;
}

export enum CatalogProductFilterGroup {
  ALL = "ALL",
  PIPE_SYSTEM = "PIPE_SYSTEM",
  BUTTERFLY_VALVE = "BUTTERFLY_VALVE",
  HEAT_COUNTER = "HEAT_COUNTER",
}

export interface FacetGroup {
  id: string;
  name: string;
  facetValues: FacetValue[];
}

export interface FacetResult {
  /**
   * @format int64
   * @min 0
   */
  count: number;
  facetGroups: FacetGroup[];
}

export interface FacetValue {
  id: string;

  /**
   * @format int64
   * @min 0
   */
  count: number;
  selected: boolean;
  enabled: boolean;
}

export interface Pageable {
  /**
   * @format int32
   * @min 0
   */
  page?: number;

  /**
   * @format int32
   * @min 1
   */
  size?: number;
  sort?: string[];
}

export interface PageOrder {
  /**
   * the total amount of elements.
   * @format int64
   */
  totalElements: number;

  /**
   * the number of total pages.
   * @format int32
   */
  totalPages: number;

  /**
   * the size of the Page.
   * @format int32
   */
  size?: number;

  /** Returns the Page content as list. */
  content?: Order[];

  /**
   * the number of the current Page. Is always non-negative.
   * @format int32
   */
  number?: number;
  sort?: Sort;
  pageable?: PageableObject;

  /**
   * the number of elements currently on this Page.
   * @format int32
   */
  numberOfElements?: number;

  /** Returns whether the current Page is the first one. */
  first?: boolean;

  /** Returns whether the current Page is the last one. */
  last?: boolean;

  /** Returns whether the Page has content at all. */
  empty?: boolean;
}

export interface PageableObject {
  /** @format int64 */
  offset?: number;
  sort?: Sort;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export interface Sort {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}

export interface PageVersion {
  /**
   * the total amount of elements.
   * @format int64
   */
  totalElements: number;

  /**
   * the number of total pages.
   * @format int32
   */
  totalPages: number;

  /**
   * the size of the Page.
   * @format int32
   */
  size?: number;

  /** Returns the Page content as list. */
  content?: Version[];

  /**
   * the number of the current Page. Is always non-negative.
   * @format int32
   */
  number?: number;
  sort?: Sort;
  pageable?: PageableObject;

  /**
   * the number of elements currently on this Page.
   * @format int32
   */
  numberOfElements?: number;

  /** Returns whether the current Page is the first one. */
  first?: boolean;

  /** Returns whether the current Page is the last one. */
  last?: boolean;

  /** Returns whether the Page has content at all. */
  empty?: boolean;
}

export interface BkpRange {
  bkp: HierarchyLevel;

  /**
   * First Page number where the BKP is present
   * @format int32
   */
  startPage: number;

  /**
   * Last Page number where the BKP is present
   * @format int32
   */
  endPage: number;
}

export interface FacetProductGroup {
  id: CatalogProductFilterGroup;
  name: string;
  facets: FacetResult;
}

export interface Preselection {
  bkpRange: BkpRange;
  facetProductGroups: FacetProductGroup[];
}

export interface Hierarchy {
  id: string;
  code: string;
  type: HierarchyType;
  hierarchyLevels?: HierarchyLevel[];
}

export interface ExtendedHierarchy {
  id: string;
  code: string;
  type: HierarchyType;
  hierarchyLevels?: ExtendedHierarchyLevel[];
}

export interface ExtendedHierarchyLevel {
  id: string;
  type: HierarchyType;
  code: string;

  /**
   * Generally a page has no name, a BKP has it.
   * @example 242.1
   */
  name?: string;

  /** Extended HierarchyLevels below the current one in the tree. */
  children?: ExtendedHierarchyLevel[];

  /**
   * the percentage extra charge which is added to the net price
   * @example 0.15
   */
  netPriceExtraCharge?: number;

  /** true means that the reseller / installer will not offer this price position */
  exclude: boolean;

  /**
   * counts the number of products (not product groups) which reference the current bkp hierarchy level (reference is via product group)
   * @format int32
   */
  numberOfProducts: number;

  /** a list of all page hierarchy levels which have product groups referencing the current bkp hierarchy level */
  pageHierarchyLevels?: HierarchyLevel[];
}

export interface FileLink {
  temporaryLink?: string;
}

export interface PageCatalogProduct {
  /**
   * the total amount of elements.
   * @format int64
   */
  totalElements: number;

  /**
   * the number of total pages.
   * @format int32
   */
  totalPages: number;

  /**
   * the size of the Page.
   * @format int32
   */
  size?: number;

  /** Returns the Page content as list. */
  content?: CatalogProduct[];

  /**
   * the number of the current Page. Is always non-negative.
   * @format int32
   */
  number?: number;
  sort?: Sort;
  pageable?: PageableObject;

  /**
   * the number of elements currently on this Page.
   * @format int32
   */
  numberOfElements?: number;

  /** Returns whether the current Page is the first one. */
  first?: boolean;

  /** Returns whether the current Page is the last one. */
  last?: boolean;

  /** Returns whether the Page has content at all. */
  empty?: boolean;
}

export interface PriceRow {
  id?: string;
  companyCode?: string;
  catalogCode?: string;
  catalogArticleId?: string;
  currencyIsoCode?: string;
  discount?: number;
  grossPrice?: number;
  netPrice?: number;
}

export interface AssemblyTime {
  id?: string;
  code?: string;
  description?: string;
  features?: string;
  diameterNominals?: DiameterNominalEnum[];
  time?: number;
  companyCode?: string;
  productDescriptionText?: string;
  catalogName?: string;
  catalogArticleId?: string;
  manufacturer?: string;
  manufacturerArticleId?: string;
  assemblyTimeType?: AssemblyTimeType;
}

export interface Company {
  /** The id of the Company */
  id: string;

  /** The code of the Company */
  code: string;

  /** The name of the Company */
  name: string;
}

export interface PageRecommendation {
  /**
   * the total amount of elements.
   * @format int64
   */
  totalElements: number;

  /**
   * the number of total pages.
   * @format int32
   */
  totalPages: number;

  /**
   * the size of the Page.
   * @format int32
   */
  size?: number;

  /** Returns the Page content as list. */
  content?: Recommendation[];

  /**
   * the number of the current Page. Is always non-negative.
   * @format int32
   */
  number?: number;
  sort?: Sort;
  pageable?: PageableObject;

  /**
   * the number of elements currently on this Page.
   * @format int32
   */
  numberOfElements?: number;

  /** Returns whether the current Page is the first one. */
  first?: boolean;

  /** Returns whether the current Page is the last one. */
  last?: boolean;

  /** Returns whether the Page has content at all. */
  empty?: boolean;
}

export interface Recommendation {
  id?: string;
  company: Company;
  source: CatalogProduct;
  target: CatalogProduct;

  /** @format int64 */
  replacedWithoutRecommendation: number;
}

export interface PipeSystem {
  id?: string;
  name?: string;
}

export enum PipeSystemPreselection {
  NO_PRESELECTION = "NO_PRESELECTION",
  WELDED = "WELDED",
  PRESSED = "PRESSED",
  PRESSEDUPTO2INCHES = "PRESSED_UP_TO_2_INCHES",
}

export interface Igh451Configuration {
  catalogCode?: string;
  catalogName?: string;
  url?: string;
}

export interface FeedbackAnswer {
  code?: string;

  /** @format int32 */
  index?: number;
  localizedValues?: Record<string, string>;
}

export interface FeedbackQuestion {
  code?: string;

  /** @format int32 */
  index?: number;
  localizedValues?: Record<string, string>;
  possibleAnswers?: FeedbackAnswer[];
  questionReason?: CustomerChangeReason;
}

export enum FeedbackScope {
  ALL = "ALL",
  ORDER = "ORDER",
  PAGE = "PAGE",
  SECTION = "SECTION",
  PRODUCT = "PRODUCT",
}

export interface FeedbackType {
  id: string;
  scope: FeedbackScope;
  name?: Record<string, string>;
  message?: Record<string, string>;
  agreementRequired?: boolean;

  /** The status of the version */
  versionStatus?: VersionStatus;
}

export interface PageCustomerChange {
  /**
   * the total amount of elements.
   * @format int64
   */
  totalElements: number;

  /**
   * the number of total pages.
   * @format int32
   */
  totalPages: number;

  /**
   * the size of the Page.
   * @format int32
   */
  size?: number;

  /** Returns the Page content as list. */
  content?: CustomerChange[];

  /**
   * the number of the current Page. Is always non-negative.
   * @format int32
   */
  number?: number;
  sort?: Sort;
  pageable?: PageableObject;

  /**
   * the number of elements currently on this Page.
   * @format int32
   */
  numberOfElements?: number;

  /** Returns whether the current Page is the first one. */
  first?: boolean;

  /** Returns whether the current Page is the last one. */
  last?: boolean;

  /** Returns whether the Page has content at all. */
  empty?: boolean;
}

export interface Catalog {
  id?: string;
  code?: string;
  name?: string;
  manufacturer?: boolean;
  contactEmailAddress?: string;
  alternativeNames?: string[];
}

export interface PageCatalog {
  /**
   * the total amount of elements.
   * @format int64
   */
  totalElements: number;

  /**
   * the number of total pages.
   * @format int32
   */
  totalPages: number;

  /**
   * the size of the Page.
   * @format int32
   */
  size?: number;

  /** Returns the Page content as list. */
  content?: Catalog[];

  /**
   * the number of the current Page. Is always non-negative.
   * @format int32
   */
  number?: number;
  sort?: Sort;
  pageable?: PageableObject;

  /**
   * the number of elements currently on this Page.
   * @format int32
   */
  numberOfElements?: number;

  /** Returns whether the current Page is the first one. */
  first?: boolean;

  /** Returns whether the current Page is the last one. */
  last?: boolean;

  /** Returns whether the Page has content at all. */
  empty?: boolean;
}

/**
 * The user that created the version
 */
export interface BackofficeUser {
  /** The id of the User */
  id: string;

  /** The email Address of the User */
  emailAddress: string;
  company: Company;
}

export interface BackofficeVersion {
  /** The id of the version */
  id: string;

  /**
   * The time the Version was created
   * @format date-time
   */
  created: string;

  /**
   * The time the Version was last modified
   * @format date-time
   */
  modified: string;

  /** The name of the version */
  name: string;

  /** The status of the version */
  status: VersionStatus;

  /** The user that created the version */
  user: BackofficeUser;

  /** The id of the parent Order */
  order: OrderReference;

  /**
   * The count of the pages in the version. Can be missing, by example when no PDF was uploaded
   * @format int32
   */
  pageCount?: number;

  /**
   * The count of the products in the version. Can be missing, by example when no PDF was uploaded
   * @format int32
   */
  productCount?: number;

  /**
   * count of the already reviewed CustomerChanges for the version
   * @format int64
   */
  reviewedCustomerChangesCount: number;

  /**
   * total count of the CustomerChanges for the version
   * @format int64
   */
  totalCustomerChangesCount: number;
}

/**
 * The id of the parent Order
 */
export interface OrderReference {
  /** The id of the Order */
  id: string;

  /** The name of the Order */
  name: string;
}

export interface ExtendedCustomerChange {
  customerChange: CustomerChange;
  searchQuery?: SearchQuery;
  productGroup: ProductGroup;
  product: Product;
}

export interface SearchQuery {
  id: string;

  /** @format date-time */
  createdDate: string;
  type: SearchQueryType;
  orderId: string;
  versionId: string;
  product: Product;
  title: string;
  manufacturer?: string;
  description?: string;
}

export enum SearchQueryType {
  PRODUCT = "PRODUCT",
}

export interface PageSynonym {
  /**
   * the total amount of elements.
   * @format int64
   */
  totalElements: number;

  /**
   * the number of total pages.
   * @format int32
   */
  totalPages: number;

  /**
   * the size of the Page.
   * @format int32
   */
  size?: number;

  /** Returns the Page content as list. */
  content?: Synonym[];

  /**
   * the number of the current Page. Is always non-negative.
   * @format int32
   */
  number?: number;
  sort?: Sort;
  pageable?: PageableObject;

  /**
   * the number of elements currently on this Page.
   * @format int32
   */
  numberOfElements?: number;

  /** Returns whether the current Page is the first one. */
  first?: boolean;

  /** Returns whether the current Page is the last one. */
  last?: boolean;

  /** Returns whether the Page has content at all. */
  empty?: boolean;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://api.dev.ineexa.ch";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Ineexa API
 * @baseUrl http://api.dev.ineexa.ch
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  rest = {
    /**
     * No description
     *
     * @tags Order
     * @name UpdateProductGroup
     * @summary Updates a product group
     * @request PUT:/rest/v2/order/{orderId}/version/{versionId}/product-group/{productGroupId}
     */
    updateProductGroup: (
      orderId: string,
      versionId: string,
      productGroupId: string,
      data: UpdateProductGroupDto,
      params: RequestParams = {},
    ) =>
      this.request<ProductGroup, void | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/product-group/${productGroupId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name DeleteProductGroup
     * @summary Deletes a product group
     * @request DELETE:/rest/v2/order/{orderId}/version/{versionId}/product-group/{productGroupId}
     */
    deleteProductGroup: (orderId: string, versionId: string, productGroupId: string, params: RequestParams = {}) =>
      this.request<void, void | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/product-group/${productGroupId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name UpdateProduct
     * @summary Updates a product
     * @request PUT:/rest/v2/order/{orderId}/version/{versionId}/product-group/{productGroupId}/product/{productId}
     */
    updateProduct: (
      orderId: string,
      versionId: string,
      productGroupId: string,
      productId: string,
      data: Product,
      params: RequestParams = {},
    ) =>
      this.request<Product, void | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/product-group/${productGroupId}/product/${productId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name DeleteProduct
     * @summary Deletes a product
     * @request DELETE:/rest/v2/order/{orderId}/version/{versionId}/product-group/{productGroupId}/product/{productId}
     */
    deleteProduct: (
      orderId: string,
      versionId: string,
      productGroupId: string,
      productId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, void | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/product-group/${productGroupId}/product/${productId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetHierarchyLevel
     * @summary Returns a HierarchyLevel with given id
     * @request GET:/rest/v2/order/{orderId}/version/{versionId}/hierarchy/{hierarchyId}/hierarchy-level/{hierarchyLevelId}
     */
    getHierarchyLevel: (
      orderId: string,
      versionId: string,
      hierarchyId: string,
      hierarchyLevelId: string,
      params: RequestParams = {},
    ) =>
      this.request<HierarchyLevel, BackendError | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/hierarchy/${hierarchyId}/hierarchy-level/${hierarchyLevelId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name UpdateHierarchyLevel
     * @summary Updates a HierarchyLevel
     * @request PUT:/rest/v2/order/{orderId}/version/{versionId}/hierarchy/{hierarchyId}/hierarchy-level/{hierarchyLevelId}
     */
    updateHierarchyLevel: (
      orderId: string,
      versionId: string,
      hierarchyId: string,
      hierarchyLevelId: string,
      data: UpdateHierarchyLevel,
      params: RequestParams = {},
    ) =>
      this.request<HierarchyLevel, BackendError | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/hierarchy/${hierarchyId}/hierarchy-level/${hierarchyLevelId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name DeleteHierarchyLevel
     * @summary Deletes a HierarchyLevel
     * @request DELETE:/rest/v2/order/{orderId}/version/{versionId}/hierarchy/{hierarchyId}/hierarchy-level/{hierarchyLevelId}
     */
    deleteHierarchyLevel: (
      orderId: string,
      versionId: string,
      hierarchyId: string,
      hierarchyLevelId: string,
      params: RequestParams = {},
    ) =>
      this.request<HierarchyLevel, BackendError>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/hierarchy/${hierarchyId}/hierarchy-level/${hierarchyLevelId}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Layout
     * @name LoadLayout
     * @summary Returns all columns information's for a user
     * @request GET:/rest/v2/layout
     */
    loadLayout: (query: { userId: string }, params: RequestParams = {}) =>
      this.request<Layout, Record<string, string>>({
        path: `/rest/v2/layout`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Layout
     * @name UpdateLayout
     * @summary Updates  entries of an order
     * @request PUT:/rest/v2/layout
     */
    updateLayout: (query: { userId: string }, data: Layout, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/layout`,
        method: "PUT",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UploadIkk
     * @summary Uploads IKK prices file for user's company
     * @request POST:/rest/v2/user/{id}/prices
     */
    uploadIkk: (id: string, data: { file: File }, params: RequestParams = {}) =>
      this.request<ImportRun, Record<string, string>>({
        path: `/rest/v2/user/${id}/prices`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ChangePassword
     * @summary Changes the password of a customer
     * @request POST:/rest/v2/user/{id}/password
     */
    changePassword: (id: string, data: UpdatePassword, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/user/${id}/password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name Authenticate
     * @summary Authenticates a user
     * @request POST:/rest/v2/user/authenticate
     */
    authenticate: (data: Credential, params: RequestParams = {}) =>
      this.request<Token, Record<string, string>>({
        path: `/rest/v2/user/authenticate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetOrders
     * @summary Gets all orders
     * @request GET:/rest/v2/order
     */
    getOrders: (query: { userId?: string; pageable: Pageable }, params: RequestParams = {}) =>
      this.request<PageOrder, Record<string, string>>({
        path: `/rest/v2/order`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name CreateOrder
     * @summary Creates an order.
     * @request POST:/rest/v2/order
     */
    createOrder: (data: CreateOrder, query?: { userId?: string }, params: RequestParams = {}) =>
      this.request<Order, Record<string, string>>({
        path: `/rest/v2/order`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name ReopenVersion
     * @summary Reopen an order version
     * @request POST:/rest/v2/order/{orderId}/versions/{versionId}/reopen
     */
    reopenVersion: (orderId: string, versionId: string, params: RequestParams = {}) =>
      this.request<Version, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/versions/${versionId}/reopen`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name RequestForPrices
     * @summary Sends a request-for-prices to resellers
     * @request POST:/rest/v2/order/{orderId}/versions/{versionId}/quote
     */
    requestForPrices: (orderId: string, versionId: string, data: QuoteRequest, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/versions/${versionId}/quote`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Async operation. Can be triggered only if the Version is in READY state. It puts the Version in PARSING state until price refresh is done, and then again in READY
     *
     * @tags Order
     * @name UpdatePrices
     * @summary Applies current prices to the products in the version. Returns the id of the cloned version with the new prices
     * @request POST:/rest/v2/order/{orderId}/versions/{versionId}/price-refresh
     */
    updatePrices: (orderId: string, versionId: string, params: RequestParams = {}) =>
      this.request<Version, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/versions/${versionId}/price-refresh`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name CloseVersion
     * @summary Closes an order version
     * @request POST:/rest/v2/order/{orderId}/versions/{versionId}/close
     */
    closeVersion: (orderId: string, versionId: string, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/versions/${versionId}/close`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name CloneVersion
     * @request POST:/rest/v2/order/{orderId}/versions/{versionId}/clone
     */
    cloneVersion: (orderId: string, versionId: string, data: CloneVersion, params: RequestParams = {}) =>
      this.request<Version, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/versions/${versionId}/clone`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Preselection
     * @name ReprocessOrder
     * @summary Parses a part of the PDF of an order again with a preselection
     * @request POST:/rest/v2/order/{orderId}/version/{versionId}/reprocess/preselection
     */
    reprocessOrder: (orderId: string, versionId: string, data: PreselectionDefinition, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/reprocess/preselection`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name CreateProductGroup
     * @summary Creates a product group
     * @request POST:/rest/v2/order/{orderId}/version/{versionId}/product-group
     */
    createProductGroup: (
      orderId: string,
      versionId: string,
      query: {
        hierarchyId: string;
        hierarchyLevelId: string;
        previousProductGroupId?: string;
        nextProductGroupId?: string;
      },
      data: CreateProductGroupDto,
      params: RequestParams = {},
    ) =>
      this.request<ProductGroup, void | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/product-group`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name CreateProduct
     * @summary Creates a product
     * @request POST:/rest/v2/order/{orderId}/version/{versionId}/product-group/{productGroupId}/product
     */
    createProduct: (
      orderId: string,
      versionId: string,
      productGroupId: string,
      data: CreateProduct,
      params: RequestParams = {},
    ) =>
      this.request<Product, void | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/product-group/${productGroupId}/product`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name CreateHierarchyLevel
     * @summary Creates a new HierarchyLevel
     * @request POST:/rest/v2/order/{orderId}/version/{versionId}/hierarchy/{hierarchyId}/hierarchy-level
     */
    createHierarchyLevel: (
      orderId: string,
      versionId: string,
      hierarchyId: string,
      data: CreateHierarchyLevel,
      params: RequestParams = {},
    ) =>
      this.request<HierarchyLevel, BackendError | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/hierarchy/${hierarchyId}/hierarchy-level`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name ReprocessOrder1
     * @summary Parses the PDF of an order again
     * @request POST:/rest/v2/order/{orderId}/reprocess
     */
    reprocessOrder1: (orderId: string, query?: { firstPage?: number }, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/reprocess`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File upload
     * @name UploadPdf
     * @summary Uploads a PDF file to an order
     * @request POST:/rest/v2/order/{orderId}/pdf
     */
    uploadPdf: (orderId: string, data: { file: File }, query?: { firstPage?: number }, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/pdf`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name SaveFeedback
     * @summary Saves a user feedback
     * @request POST:/rest/feedback
     */
    saveFeedback: (data: Feedback, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/feedback`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name CreateCredential
     * @summary Create new credential
     * @request POST:/rest/company/{companyCode}/credentials/{type}/catalog/{catalogCode}
     */
    createCredential: (
      companyCode: string,
      type: CredentialType,
      catalogCode: string,
      data: Credential,
      params: RequestParams = {},
    ) =>
      this.request<void, Record<string, string>>({
        path: `/rest/company/${companyCode}/credentials/${type}/catalog/${catalogCode}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name DeleteCredential
     * @summary Deletes a credential
     * @request DELETE:/rest/company/{companyCode}/credentials/{type}/catalog/{catalogCode}
     */
    deleteCredential: (companyCode: string, type: CredentialType, catalogCode: string, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/company/${companyCode}/credentials/${type}/catalog/${catalogCode}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdateCredential
     * @summary Update credential
     * @request PATCH:/rest/company/{companyCode}/credentials/{type}/catalog/{catalogCode}
     */
    updateCredential: (
      companyCode: string,
      type: CredentialType,
      catalogCode: string,
      data: Credential,
      params: RequestParams = {},
    ) =>
      this.request<void, Record<string, string>>({
        path: `/rest/company/${companyCode}/credentials/${type}/catalog/${catalogCode}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name ReloadRemotePrices
     * @summary Loads price from the specified remote source
     * @request POST:/rest/company/{companyCode}/credentials/{type}/catalog/{catalogCode}/reload
     */
    reloadRemotePrices: (companyCode: string, type: CredentialType, catalogCode: string, params: RequestParams = {}) =>
      this.request<ImportRun, Record<string, string>>({
        path: `/rest/company/${companyCode}/credentials/${type}/catalog/${catalogCode}/reload`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Catalog
     * @name GetCatalogs
     * @summary Gets all Catalogs
     * @request GET:/rest/catalog
     */
    getCatalogs: (
      query: { isManufacturer?: boolean; withContactEmail?: boolean; pageable: Pageable },
      params: RequestParams = {},
    ) =>
      this.request<PageCatalog, Record<string, string>>({
        path: `/rest/catalog`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Catalog
     * @name UploadCatalogs
     * @summary Imports a Catalog XML file
     * @request POST:/rest/catalog
     */
    uploadCatalogs: (
      query: { catalogFormat?: CatalogFormat; catalogName: string },
      data: { file: File },
      params: RequestParams = {},
    ) =>
      this.request<void, Record<string, string>>({
        path: `/rest/catalog`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetOrder
     * @summary Gets an order
     * @request GET:/rest/v2/order/{orderId}
     */
    getOrder: (orderId: string, query?: { fetchVersions?: boolean }, params: RequestParams = {}) =>
      this.request<Order, Record<string, string>>({
        path: `/rest/v2/order/${orderId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name ArchiveOrder
     * @summary Deletes an order
     * @request DELETE:/rest/v2/order/{orderId}
     */
    archiveOrder: (orderId: string, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/order/${orderId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name UpdateOrder
     * @summary Updates the order
     * @request PATCH:/rest/v2/order/{orderId}
     */
    updateOrder: (orderId: string, data: UpdateOrderDto, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/order/${orderId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetVersion
     * @request GET:/rest/v2/order/{orderId}/versions/{versionId}
     */
    getVersion: (orderId: string, versionId: string, params: RequestParams = {}) =>
      this.request<Version, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/versions/${versionId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name UpdateName
     * @summary Updates the version name
     * @request PATCH:/rest/v2/order/{orderId}/versions/{versionId}
     */
    updateName: (orderId: string, versionId: string, data: UpdateVersionDto, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/versions/${versionId}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name GetChanges
     * @summary Gets the CustomerChanges from a given Version
     * @request GET:/rest/customerchange
     */
    getChanges: (query: { orderId: string; versionId: string; pageable: Pageable }, params: RequestParams = {}) =>
      this.request<PageCustomerChange, PageCustomerChange | Record<string, string>>({
        path: `/rest/customerchange`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name AnswerQuestion
     * @summary Stores the customer reply to a Feedback Question
     * @request PATCH:/rest/customerchange
     */
    answerQuestion: (
      query: { orderId: string; versionId: string; productId: string },
      data: FeedbackQuestionReply,
      params: RequestParams = {},
    ) =>
      this.request<void, void | Record<string, string>>({
        path: `/rest/customerchange`,
        method: "PATCH",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name GetUser
     * @summary Gets a user
     * @request GET:/rest/v2/user/{id}
     */
    getUser: (id: string, params: RequestParams = {}) =>
      this.request<User, BackendError | Record<string, string>>({
        path: `/rest/v2/user/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Preselection
     * @name GetFacetGroups
     * @summary Returns the facet groups for a specific query
     * @request GET:/rest/v2/preselection/facet
     */
    getFacetGroups: (
      query: { catalogProductFilterGroup: CatalogProductFilterGroup; query?: string[] },
      params: RequestParams = {},
    ) =>
      this.request<FacetResult, Record<string, string>>({
        path: `/rest/v2/preselection/facet`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetOrderVersions
     * @request GET:/rest/v2/order/{orderId}/versions
     */
    getOrderVersions: (orderId: string, query: { pageable: Pageable }, params: RequestParams = {}) =>
      this.request<PageVersion, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/versions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Preselection
     * @name GetPreselection
     * @summary Returns the initial preselection configuration
     * @request GET:/rest/v2/order/{orderId}/version/{versionId}/preselection
     */
    getPreselection: (orderId: string, versionId: string, query: { pageId: string }, params: RequestParams = {}) =>
      this.request<Preselection, Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/preselection`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetHierarchies
     * @summary Lists all Hierarchies of a Version
     * @request GET:/rest/v2/order/{orderId}/version/{versionId}/hierarchy
     */
    getHierarchies: (orderId: string, versionId: string, params: RequestParams = {}) =>
      this.request<Hierarchy[], Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/hierarchy`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetHierarchy
     * @summary Returns a Hierarchy with given id
     * @request GET:/rest/v2/order/{orderId}/version/{versionId}/hierarchy/{hierarchyId}
     */
    getHierarchy: (orderId: string, versionId: string, hierarchyId: string, params: RequestParams = {}) =>
      this.request<Hierarchy, BackendError | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/hierarchy/${hierarchyId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetProductGroups
     * @summary Returns all product groups for a specific hierarchy and hierarchy level
     * @request GET:/rest/v2/order/{orderId}/version/{versionId}/hierarchy/{hierarchyId}/hierarchy-level/{hierarchyLevelId}/product-group
     */
    getProductGroups: (
      orderId: string,
      versionId: string,
      hierarchyId: string,
      hierarchyLevelId: string,
      params: RequestParams = {},
    ) =>
      this.request<ProductGroup[], ProductGroup[] | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/hierarchy/${hierarchyId}/hierarchy-level/${hierarchyLevelId}/product-group`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order
     * @name GetExtendedHierarchy
     * @summary Returns an Extended Hierarchy with given id
     * @request GET:/rest/v2/order/{orderId}/version/{versionId}/extended-hierarchy/{hierarchyId}
     */
    getExtendedHierarchy: (orderId: string, versionId: string, hierarchyId: string, params: RequestParams = {}) =>
      this.request<ExtendedHierarchy, BackendError | Record<string, string>>({
        path: `/rest/v2/order/${orderId}/version/${versionId}/extended-hierarchy/${hierarchyId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name LoadOriginalFile
     * @summary Returns the URL for an uploaded file
     * @request GET:/rest/v2/file/original
     */
    loadOriginalFile: (query: { orderId: string; userId?: string }, params: RequestParams = {}) =>
      this.request<FileLink, Record<string, string>>({
        path: `/rest/v2/file/original`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name LoadExport
     * @summary Returns the URL for an export file
     * @request GET:/rest/v2/file/export
     */
    loadExport: (query: { orderId: string; versionId: string; userId?: string }, params: RequestParams = {}) =>
      this.request<FileLink, Record<string, string>>({
        path: `/rest/v2/file/export`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Monitoring
     * @name Ping
     * @summary Pings the application
     * @request GET:/rest/status/ping
     */
    ping: (params: RequestParams = {}) =>
      this.request<any, Record<string, string> | string>({
        path: `/rest/status/ping`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Search
     * @name GetProducts
     * @summary Searches for products
     * @request GET:/rest/search/product
     */
    getProducts: (
      query: {
        title: string;
        manufacturer?: string;
        sectionType?: string;
        description?: string;
        orderId?: string;
        versionId?: string;
        productCode?: string;
        pageable: Pageable;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageCatalogProduct, Record<string, string>>({
        path: `/rest/search/product`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Search
     * @name GetPrice
     * @summary Searches for price of a product for the company of the currently logged in user
     * @request GET:/rest/search/price
     */
    getPrice: (
      query: { catalogName?: string; catalogCode?: string; catalogArticleId: string },
      params: RequestParams = {},
    ) =>
      this.request<PriceRow, PriceRow | void>({
        path: `/rest/search/price`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Search
     * @name SearchManufacturers
     * @summary Searches for a list of manufacturers
     * @request GET:/rest/search/manufacturer
     */
    searchManufacturers: (query: { name: string }, params: RequestParams = {}) =>
      this.request<string[], Record<string, string>>({
        path: `/rest/search/manufacturer`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Search
     * @name SearchProductDescriptions
     * @summary Searches for a list of product descriptions
     * @request GET:/rest/search/description
     */
    searchProductDescriptions: (query: { name: string }, params: RequestParams = {}) =>
      this.request<string[], Record<string, string>>({
        path: `/rest/search/description`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Search
     * @name Search
     * @summary Searches for assembly times of the company of the currently logged in user
     * @request GET:/rest/search/assemblytime
     */
    search: (
      query?: {
        manufacturer?: string;
        description?: string;
        type?: string;
        catalogName?: string;
        catalogArticleId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssemblyTime[], Record<string, string>>({
        path: `/rest/search/assemblytime`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Provide either only the catalogProductId, or both catalogName and articleNumber. Using catalogProductId is faster
     *
     * @tags Recommendation
     * @name GetRecommendations
     * @summary Gets all company-specific recommendations for a given ProductV1
     * @request GET:/rest/recommendation
     */
    getRecommendations: (
      query: { catalogProductId?: string; catalogName?: string; articleNumber?: string; pageable: Pageable },
      params: RequestParams = {},
    ) =>
      this.request<PageRecommendation, Record<string, string>>({
        path: `/rest/recommendation`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Preselection
     * @name GetPreselection1
     * @summary Returns the initial preselection configuration
     * @request GET:/rest/preselection
     */
    getPreselection1: (query?: { orderId?: string; pageId?: string }, params: RequestParams = {}) =>
      this.request<Preselection, Record<string, string>>({
        path: `/rest/preselection`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Preselection
     * @name GetFacetGroups1
     * @summary Returns the facet groups for a specific query
     * @request GET:/rest/preselection/facet
     */
    getFacetGroups1: (
      query: { catalogProductFilterGroup: CatalogProductFilterGroup; query?: string[] },
      params: RequestParams = {},
    ) =>
      this.request<FacetResult, Record<string, string>>({
        path: `/rest/preselection/facet`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pipe Systems
     * @name GetPipesystems
     * @summary Gets all Pipe Systems
     * @request GET:/rest/pipesystems
     */
    getPipesystems: (params: RequestParams = {}) =>
      this.request<PipeSystem[], Record<string, string>>({
        path: `/rest/pipesystems`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pipe Systems
     * @name GetPipesystempreselections
     * @summary Gets all Pipe Systems Preselections
     * @request GET:/rest/pipesystempreselections
     */
    getPipesystempreselections: (params: RequestParams = {}) =>
      this.request<PipeSystemPreselection[], Record<string, string>>({
        path: `/rest/pipesystempreselections`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags IGH 451
     * @name GetIgh451Configurations
     * @summary Gets all IGH 451 Configurations
     * @request GET:/rest/igh451
     */
    getIgh451Configurations: (params: RequestParams = {}) =>
      this.request<Igh451Configuration[], Record<string, string>>({
        path: `/rest/igh451`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name GetFeedbackQuestions
     * @summary Lists all feedbackQuestions
     * @request GET:/rest/feedbackquestion
     */
    getFeedbackQuestions: (query?: { questionReason?: CustomerChangeReason }, params: RequestParams = {}) =>
      this.request<FeedbackQuestion[], Record<string, string>>({
        path: `/rest/feedbackquestion`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name GetFedbackTypes
     * @summary Returns the available feedback types
     * @request GET:/rest/feedback/type
     */
    getFedbackTypes: (params: RequestParams = {}) =>
      this.request<FeedbackType[], Record<string, string>>({
        path: `/rest/feedback/type`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags IGH 451
     * @name GetCompanyImportRuns
     * @summary Lists all Import Runs of a company
     * @request GET:/rest/company/{companyCode}/importruns
     */
    getCompanyImportRuns: (companyCode: string, query?: { from?: string; to?: string }, params: RequestParams = {}) =>
      this.request<ImportRun[], Record<string, string>>({
        path: `/rest/company/${companyCode}/importruns`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags IGH 451
     * @name GetCompanyImportRun
     * @summary get One ImportRun by Id
     * @request GET:/rest/company/{companyCode}/importruns/{importRunId}
     */
    getCompanyImportRun: (companyCode: string, importRunId: string, params: RequestParams = {}) =>
      this.request<ImportRun, Record<string, string>>({
        path: `/rest/company/${companyCode}/importruns/${importRunId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name GetCompanyCredentials
     * @summary Lists all credentials of a company
     * @request GET:/rest/company/{companyCode}/credentials
     */
    getCompanyCredentials: (companyCode: string, params: RequestParams = {}) =>
      this.request<Credential[], Record<string, string>>({
        path: `/rest/company/${companyCode}/credentials`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  backoffice = {
    /**
     * No description
     *
     * @tags Backoffice
     * @name GetCustomerChange
     * @summary Retrieves a CustomerChange with linked entities
     * @request GET:/backoffice/version/{versionId}/customerchange/{customerChangeId}
     */
    getCustomerChange: (versionId: string, customerChangeId: string, params: RequestParams = {}) =>
      this.request<ExtendedCustomerChange, Record<string, string>>({
        path: `/backoffice/version/${versionId}/customerchange/${customerChangeId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name UpdateCustomerChange
     * @summary Updates a CustomerChange
     * @request PUT:/backoffice/version/{versionId}/customerchange/{customerChangeId}
     */
    updateCustomerChange: (
      versionId: string,
      customerChangeId: string,
      data: CustomerUpdateChange,
      params: RequestParams = {},
    ) =>
      this.request<CustomerChange, Record<string, string>>({
        path: `/backoffice/version/${versionId}/customerchange/${customerChangeId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name GetSynonym
     * @summary Gets a Synonym by its id
     * @request GET:/backoffice/synonym/{synonymId}
     */
    getSynonym: (synonymId: string, params: RequestParams = {}) =>
      this.request<Synonym, Record<string, string>>({
        path: `/backoffice/synonym/${synonymId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name UpdateSynonym
     * @summary Updates the Synonym
     * @request PUT:/backoffice/synonym/{synonymId}
     */
    updateSynonym: (synonymId: string, data: Synonym, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/backoffice/synonym/${synonymId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name DeleteSynonym
     * @summary Removes a Synonym with given id
     * @request DELETE:/backoffice/synonym/{synonymId}
     */
    deleteSynonym: (synonymId: string, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/backoffice/synonym/${synonymId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name GetSynonyms1
     * @summary Gets all Synonyms
     * @request GET:/backoffice/synonym
     */
    getSynonyms1: (query: { pageable: Pageable }, params: RequestParams = {}) =>
      this.request<PageSynonym, Record<string, string>>({
        path: `/backoffice/synonym`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name CreateSynonym
     * @summary Creates a Synonym
     * @request POST:/backoffice/synonym
     */
    createSynonym: (data: Synonym, params: RequestParams = {}) =>
      this.request<void, Record<string, string>>({
        path: `/backoffice/synonym`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name GetSynonyms
     * @summary Returns Synonyms applied to a given text search
     * @request POST:/backoffice/synonym/analyze
     */
    getSynonyms: (data: string, params: RequestParams = {}) =>
      this.request<Synonym[], Record<string, string>>({
        path: `/backoffice/synonym/analyze`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name GetVersions
     * @summary Returns all Versions in the system
     * @request GET:/backoffice/version
     */
    getVersions: (
      query: { versionStatus?: VersionStatus[]; companyCode?: string; pageable: Pageable },
      params: RequestParams = {},
    ) =>
      this.request<BackofficeVersion[], Record<string, string>>({
        path: `/backoffice/version`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name GetCustomerChanges
     * @summary Returns all CustomerChanges for a version
     * @request GET:/backoffice/version/{versionId}/customerchange
     */
    getCustomerChanges: (
      versionId: string,
      query: { type?: CustomerChangeType; reason?: CustomerChangeReason; pageable: Pageable },
      params: RequestParams = {},
    ) =>
      this.request<CustomerChange[], Record<string, string>>({
        path: `/backoffice/version/${versionId}/customerchange`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name GetSearchQuery
     * @summary Gets the last SearchQuery used  on the Product of the CustomerChange
     * @request GET:/backoffice/version/{versionId}/customerchange/{customerChangeId}/searchQuery
     */
    getSearchQuery: (versionId: string, customerChangeId: string, params: RequestParams = {}) =>
      this.request<SearchQuery, Record<string, string>>({
        path: `/backoffice/version/${versionId}/customerchange/${customerChangeId}/searchQuery`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name GetCompanies
     * @summary Returns all Companies in the system
     * @request GET:/backoffice/company
     */
    getCompanies: (query: { pageable: Pageable }, params: RequestParams = {}) =>
      this.request<Company[], Record<string, string>>({
        path: `/backoffice/company`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backoffice
     * @name SearchCompanies
     * @summary Returns all Companies in the system
     * @request GET:/backoffice/company/search
     */
    searchCompanies: (query: { text: string }, params: RequestParams = {}) =>
      this.request<Company[], Record<string, string>>({
        path: `/backoffice/company/search`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
}
