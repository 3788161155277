import React, {ChangeEvent, MouseEvent, useCallback, useEffect, useState} from 'react';
import conf from '../config';
import {Button, Col, Container, Pagination, Row, Spinner,} from 'react-bootstrap';
import NewCompany from "./NewCompany";
import ExistingCompany from "./ExistingCompany";
import {useSelector} from "react-redux";
import util from "../util";
import {Trans, useTranslation} from 'react-i18next';
import {AxiosResponse} from "axios";
import {Token} from "../types/token";
import FilterCompany from "./FilterCompany";

export interface CompanyDto {
  id: string;
  code: string;
  name: string;
  suissetecMember: boolean;
  catalogCodes: string[];
  assemblyHourlyRate: string;
  internalAssemblyHourlyRate: string;
  externalId: string;
  status: string;
  reseller: boolean;
}

export interface UpdateCompanyDto {
  name: string;
  suissetecMember: boolean;
  catalogCodes: string[];
  assemblyHourlyRate: string;
  internalAssemblyHourlyRate: string;
  externalId: string;
  status: string;
  reseller:boolean;
}

export interface CatalogCodeDto {
  id: string,
  code: string,
  name: string,
  manufacturer: string,
  contactEmailAddress: string,
  alternativeNames: [],
}

export const companyStatusList = ["ACTIVE", "DELETED"];

const Companies: React.FC = () => {

  const {t} = useTranslation();
  const [companies, setCompanies] = useState<CompanyDto[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [addNewFlag, setAddNewFlag] = useState(false);
  const [paginationItems, setPaginationItems] = useState<Element[]>([]);
  const [checkedButton, setCheckedButton] = useState("ACTIVE");
  const [statusFilter, setStatusFilter] = useState("ACTIVE");
  const token: Token = useSelector((state: any) => state.addAuthData);
  const [catalogCodes, setCatalogCodes] = useState<CatalogCodeDto[]>([]);

  const fetchCatalog = async () => {

    let url = conf.urls.serviceUrl + conf.urls.fetchCatalogCodesAPI + "?size=2000";;
    util.serviceCallWrapper({
          method: 'GET',
          url: url,
          headers: {Authorization: `Bearer ${token.accessToken}`}
        },
        (result: AxiosResponse) => {
          if (result.status === 200) {
            setCatalogCodes(result.data.content);
          }
        },
        {},
        () => {
          setCatalogCodes([]);
        },
        true
    );
  }

  const updateCompanyListElements = useCallback((pageNumber: number, queryParameterProp?: string) => {
    const queryParameter = queryParameterProp !== undefined ? queryParameterProp : "";
    const url = conf.urls.serviceUrl + conf.urls.orderOverviewCompanyAPI + "?page=" + pageNumber + "&size=10" + queryParameter;

    util.serviceCallWrapper({
          method: "GET",
          url: url,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          let companies: CompanyDto[] = result.data.content.map((companyIn: CompanyDto) => {
            return {
              id: companyIn.id,
              code: companyIn.code,
              name: companyIn.name,
              assemblyHourlyRate: parseFloat(companyIn.assemblyHourlyRate).toFixed(2),
              internalAssemblyHourlyRate: parseFloat(companyIn.internalAssemblyHourlyRate).toFixed(2),
              status: companyIn.status,
              catalogCodes: companyIn.catalogCodes,
              suissetecMember: companyIn.suissetecMember,
              externalId: companyIn.externalId,
              reseller: companyIn.reseller
            }
          });
          setCompanies(companies);
          setTotalPages(result.data.totalPages > 0 ? result.data.totalPages - 1 : 0);
        },
        {},
        () => {
        },
        false
    );
  }, [token, statusFilter]);

  const openPage = useCallback((pageNumber: number) => {
    let queryParameter = statusFilter === "ALL" ? "" : "&status=" + statusFilter;
    updateCompanyListElements(pageNumber, queryParameter);
    setCurrentPage(pageNumber);
  }, [updateCompanyListElements, setCurrentPage]);

  const createPagination = useCallback((): void => {
    let pages: any[] = [];
    for (let number = 0; number <= totalPages; number++) {
      pages.push(
          <Pagination.Item key={number} active={number === currentPage}
                           onClick={() => openPage(number)}>
            {number + 1}
          </Pagination.Item>,
      );
      setPaginationItems(pages);
    }
  }, [openPage, currentPage, totalPages]);

  useEffect(() => {
    document.title = t('company.companyMain.companyMainTitle');
    createPagination();
  }, [createPagination, currentPage, token]);

  useEffect(() => {
    fetchCatalog();
    updateCompanyListElements(0, "&status=ACTIVE");
  }, []);

  const openFirstPage = () => {
    openPage(0);
  };

  const openLastPage = () => {
    openPage(totalPages);
  };

  const openNextPage = () => {
    if (currentPage < totalPages) {
      openPage(currentPage + 1);
    }
  };

  const openPreviousPage = () => {
    if (currentPage > 0) {
      openPage(currentPage - 1);
    }
  };

  const handleDeleteClick = (event: MouseEvent, company: CompanyDto): void => {
    util.serviceCallWrapper({
          method: 'DELETE',
          url: conf.urls.serviceUrl + conf.urls.orderOverviewCompanyAPI + "/" + company.id,
          headers: {Authorization: `Bearer ${token.accessToken}`},
        },
        () => {
          companyStatusList.includes(statusFilter) ? updateCompanyListElements(currentPage, "&status=" + statusFilter) : updateCompanyListElements(currentPage)
        },
        {
          200: {
            'SUCCESS': 'Company with id = ' + company.id + ' is deleted.'
          },
          404: {
            'ERROR': 'Company with id = ' + company.id + ' to be deleted is not found!'
          },
        },
        () => {
        },
        true
    );
  };

  const handleUpdateClick = (event: MouseEvent, company: CompanyDto) => {
    util.serviceCallWrapper({
          method: 'PUT',
          url: conf.urls.serviceUrl + conf.urls.orderOverviewCompanyAPI + "/" + company.id,
          data: getUpdatedCompanyDto(company),
          headers: {Authorization: `Bearer ${token.accessToken}`}
        },
        () => {
          companyStatusList.includes(statusFilter) ? updateCompanyListElements(currentPage, "&status=" + statusFilter) : updateCompanyListElements(currentPage)
        },
        {
          200: {
            'SUCCESS': 'Company with id = ' + company.id + ' is updated.'
          }
        },
        () => {
        },
        true
    );
  };

  const getUpdatedCompanyDto = (company: CompanyDto): UpdateCompanyDto => {
    let updatedCompanyDto: UpdateCompanyDto = {
      name: '',
      suissetecMember: false,
      catalogCodes: [],
      assemblyHourlyRate: '',
      internalAssemblyHourlyRate: '',
      externalId: '',
      status: '',
      reseller: false
    }
    updatedCompanyDto.name = company.name;
    updatedCompanyDto.suissetecMember = company.suissetecMember;
    updatedCompanyDto.catalogCodes = company.catalogCodes;
    updatedCompanyDto.assemblyHourlyRate = (Math.round((parseFloat(company.assemblyHourlyRate) + Number.EPSILON) * 100) / 100).toFixed(2);
    updatedCompanyDto.internalAssemblyHourlyRate = (Math.round((parseFloat(company.internalAssemblyHourlyRate) + Number.EPSILON) * 100) / 100).toFixed(2);
    updatedCompanyDto.externalId = company.externalId;
    updatedCompanyDto.reseller = company.reseller;
    updatedCompanyDto.status=company.status;
    return updatedCompanyDto;
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>, company: CompanyDto) => {
    let updatedCompanies = companies.slice();
    updatedCompanies.map((i) => {
      if (i.id === company.id) {
        return Object.assign(i, {name: (event.target as HTMLInputElement).value});
      }
      return i;
    });
    setCompanies(updatedCompanies);
  };

  const handleAssemblyHourlyRate = (event: ChangeEvent<HTMLInputElement>, company: CompanyDto) => {
    if ((event.target as HTMLInputElement).value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
      let updatedCompanies = companies.slice();
      updatedCompanies.map((i) => {
        if (i.id === company.id) {
          return Object.assign(i, {assemblyHourlyRate: (event.target as HTMLInputElement).value});
        }
        return i;
      });
      setCompanies(updatedCompanies);
    }
  };

  const handleInternalAssemblyHourlyRate = (event: ChangeEvent<HTMLInputElement>, company: CompanyDto) => {
    if ((event.target as HTMLInputElement).value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
      let updatedCompanies = companies.slice();
      updatedCompanies.map((i) => {
        if (i.id === company.id) {
          return Object.assign(i, {internalAssemblyHourlyRate: (event.target as HTMLInputElement).value});
        }
        return i;
      });
      setCompanies(updatedCompanies);
    }
  };

  const handleSuissetecMemberChange = (event: ChangeEvent<HTMLInputElement>, company: CompanyDto) => {
    let updatedCompanies = companies.slice();
    updatedCompanies.map((i) => {
      if (i.id === company.id) {
        return Object.assign(i, {suissetecMember: (event.target as HTMLInputElement).checked});
      }
      return i;
    });
    setCompanies(updatedCompanies);
  };

  const handleExternalIdChange = (event: ChangeEvent<HTMLInputElement>, company: CompanyDto) => {
    let updatedCompanies = companies.slice();
    updatedCompanies.map((i) => {
      if (i.id === company.id) {
        return Object.assign(i, {externalId: (event.target as HTMLInputElement).value});
      }
      return i;
    });
    setCompanies(updatedCompanies);
  };

  const handleResellerChange = (event: ChangeEvent<HTMLInputElement>, company: CompanyDto) => {
    let updatedCompanies = companies.slice();
    updatedCompanies.map((i) => {
      if (i.id === company.id) {
        return Object.assign(i, {reseller: (event.target as HTMLInputElement).checked});
      }
      return i;
    });
    setCompanies(updatedCompanies);
  };


  const getCatalogCodesFromCatalogNames = (catalogNames: string[]) => {
    let catalogCodesList: string[] = [];
    for (let i = 0; i < catalogNames.length; i++) {
      let selectedName = catalogNames[i].trim();
      const index = catalogCodes.findIndex(i => i.name.trim() === selectedName)
      if (index !== -1) {
        catalogCodesList.push(catalogCodes[index].code);
      }
    }
    return catalogCodesList
  }

  const toggleAddNewFlag = () => {
    setAddNewFlag(!addNewFlag);
  }

  const toggleCheckedButton = (value: string): void => {
    setCheckedButton(value);
  }


  const renderCompany = (company: CompanyDto) => {
    return (
        <ExistingCompany key={company.id}
                         company={company}
                         handleDelete={(e: MouseEvent) => handleDeleteClick(e, company)}
                         handleUpdate={(e: MouseEvent) => handleUpdateClick(e, company)}
                         handleName={(e: ChangeEvent<HTMLInputElement>) => handleNameChange(e, company)}
                         handleSuissetecMember={(e: ChangeEvent<HTMLInputElement>) => handleSuissetecMemberChange(e, company)}
                         catalogCodes={catalogCodes}
                         handleAssemblyHourlyRate={(e: ChangeEvent<HTMLInputElement>) => handleAssemblyHourlyRate(e, company)}
                         handleInternalAssemblyHourlyRate={(e: ChangeEvent<HTMLInputElement>) => handleInternalAssemblyHourlyRate(e, company)}
                         handleExternalId={(e: ChangeEvent<HTMLInputElement>) => handleExternalIdChange(e, company)}
                         handleReseller={(e: ChangeEvent<HTMLInputElement>) => handleResellerChange(e, company)}
        />
    );
  }

  return (
      <div>
        <Row>
          <Col xl={1} className="hidden-lg"/>
          <Col xl={10} className="mainContent">
            <Container fluid className="company">
              {(token.authenticated) ?
                          <div>
                            <Row className="pageName">

                              <Col xs={{span: 12}} className="label">
                                <Trans
                                    i18nKey="company.company.companyMainLabel.label" // optional -> fallbacks to defaults if not provided
                                    defaults="Company -  Overview" // optional defaultValue
                                    components={{tag: <span/>}}
                                />
                              </Col>
                            </Row>

                            {addNewFlag ?
                                <div>
                                  <Row className="backofficeTable">
                                    <Col xs={3} className="tab"/>
                                    <Col xs={1} className="tab"/>
                                    <Col xs={{offset: 6, span: 2}} className="features">
                                      <FilterCompany
                                          updateCompanyListElements={updateCompanyListElements}
                                          token={token}
                                          statusFilterVal={statusFilter}
                                          toggleCheckedButton={toggleCheckedButton}/>
                                      <Button className="addNew"
                                              onClick={() => (toggleAddNewFlag())}>
                                        <img src="/common-icons/plus-circle-black.svg"
                                             style={{width: 18, height: 18}}
                                             alt=''/>
                                        <span
                                            className="value"> <> {t('company.companyMain.backofficeTableAdd.value')} </> </span>
                                      </Button>
                                    </Col>
                                  </Row>
                                  <NewCompany
                                      updateCompanyListElements={updateCompanyListElements}
                                      currentPage={currentPage}
                                      catalogCodes={catalogCodes}
                                      setCheckedButton={setCheckedButton}
                                      getCatalogCodesFromCatalogNames={getCatalogCodesFromCatalogNames}

                                  />
                                  <Row className="backofficeTable">
                                    <Col xs={{span: 9}} className="tab">
                                      <Button
                                          className={checkedButton === "ACTIVE" ? "button active" : "button"}
                                          onClick={() => {
                                            updateCompanyListElements(0, "&status=ACTIVE");
                                            setStatusFilter("ACTIVE");
                                            setCurrentPage(0);
                                            toggleCheckedButton("ACTIVE")
                                          }}><>{t('company.companyMain.backofficeTableTab.active')}</>
                                      </Button>

                                      <Button
                                          className={checkedButton === "ALL" ? "button active" : "button"}
                                          onClick={() => {
                                            updateCompanyListElements(0);
                                            setStatusFilter("");
                                            setCurrentPage(0);
                                            toggleCheckedButton("ALL")
                                          }}><>{t('company.companyMain.backofficeTableTab.all')}</>
                                      </Button>

                                      <Button
                                          className={checkedButton === "DELETED" ? "button active" : "button"}
                                          onClick={() => {
                                            updateCompanyListElements(0, "&status=DELETED");
                                            setStatusFilter("DELETED");
                                            setCurrentPage(0);
                                            toggleCheckedButton("DELETED")
                                          }}><>{t('company.companyMain.backofficeTableTab.deleted')}</>
                                      </Button>
                                    </Col>
                                    <Col xs={{span: 3}} className="features"/>
                                  </Row>
                                </div>
                                :
                                <Row className="backofficeTable">
                                  <Col xs={{span: 9}} className="tab">
                                    <Button
                                        className={checkedButton === "ACTIVE" ? "button active" : "button"}
                                        onClick={() => {
                                          updateCompanyListElements(0, "&status=ACTIVE");
                                          setStatusFilter("ACTIVE");
                                          setCurrentPage(0);
                                          toggleCheckedButton("ACTIVE")
                                        }}><>{t('company.companyMain.backofficeTableTab.active')}</>
                                    </Button>

                                    <Button
                                        className={checkedButton === "ALL" ? "button active" : "button"}
                                        onClick={() => {
                                          updateCompanyListElements(0);
                                          setStatusFilter("ALL");
                                          setCurrentPage(0);
                                          toggleCheckedButton("ALL")
                                        }}><>{t('company.companyMain.backofficeTableTab.all')}</>
                                    </Button>

                                    <Button
                                        className={checkedButton === "DELETED" ? "button active" : "button"}
                                        onClick={() => {
                                          updateCompanyListElements(0, "&status=DELETED");
                                          setStatusFilter("DELETED");
                                          setCurrentPage(0);
                                          toggleCheckedButton("DELETED")
                                        }}><>{t('company.companyMain.backofficeTableTab.deleted')}</>
                                    </Button>
                                  </Col> <Col xs={{span: 3}} className="features">

                                  <FilterCompany updateCompanyListElements={updateCompanyListElements}
                                                 token={token}
                                                 statusFilterVal={statusFilter}
                                                 toggleCheckedButton={toggleCheckedButton}/>
                                  <Button className="addNew"
                                          onClick={() => (toggleAddNewFlag())}>
                                    <img src="/common-icons/plus-circle-black.svg"
                                         style={{width: 18, height: 18}}
                                         alt=''/>
                                    <span
                                        className="value"> <> {t('company.companyMain.backofficeTableAdd.value')} </> </span>
                                  </Button>
                                </Col>
                                </Row>
                            }

                            <Row className="tableHeader">
                              <Col xs={2} className="label">
                                <>{t('company.companyMain.tableHeader.name.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('company.companyMain.tableHeader.code.label')}</>
                              </Col>
                              <Col xs={2} className="label">
                                <>{t('company.companyMain.tableHeader.CatalogCode.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('company.companyMain.tableHeader.rate.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('company.companyMain.tableHeader.internalRate.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('company.companyMain.tableHeader.SuisseTechMember.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('company.companyMain.tableHeader.externalId.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('company.companyMain.tableHeader.reseller.label')}</>
                              </Col>
                              <Col xs={2} className="label"> </Col>

                            </Row>
                            {companies.map((company) => renderCompany(company))}
                            <Row className="paging">
                              <Col xs={5} className="content"/>
                              <Col xs={7} className="content">
                                <Pagination className="buttons">
                                  <Pagination.First onClick={openFirstPage}/>
                                  <Pagination.Prev onClick={openPreviousPage}/>
                                  <Pagination><>{paginationItems}</>
                                  </Pagination>
                                  <Pagination.Next onClick={openNextPage}/>
                                  <Pagination.Last onClick={openLastPage}/>
                                </Pagination>
                              </Col>
                            </Row>
                          </div>
                  :
                  ""
              }
            </Container>
          </Col>
          <Col xl={1} className="hidden-lg"/>
        </Row>

      </div>
  );
};

export default Companies;
