import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

export interface MessageProps {
  level: string,
  message: string
}

const Message = ({level, message} : MessageProps) => {
  const {t} = useTranslation();

  return (
      <div className="messages">
        {level === "SUCCESS" ?
            <div>
              <Row className="content">
                <Col xs={2}>
                  <img src="/messages/background-green.svg"
                       alt='check-circle-fill' className="background"/>
                  <img src="/messages/check-circle-fill.svg"
                       style={{width: 16, height: 16}} alt='check-circle-fill'
                       className="level-image"/>
                </Col>
                <Col xs={10}>
                  <span className="level"> <>{t(
                      'message.messages.messageSuccessText')}</> </span>: {message}
                </Col>
              </Row>
            </div>
            :
            level === "WARNING" ?
                <div>
                  <Row className="content">
                    <Col xs={2}>
                      <img src="/messages/background-yellow.svg"
                           alt='background-yellow' className="background"/>
                      <img src="/messages/circle-warning.svg"
                           style={{width: 16, height: 16}} alt='circle-warning'
                           className="level-image"/>
                    </Col>
                    <Col xs={10}>
                      <span className="level"> <>{t(
                          'message.messages.warningMessageText')}</> </span>: {message}
                    </Col>
                  </Row>
                </div>
                :
                <div>
                  <Row className="content">
                    <Col xs={2}>
                      <img src="/messages/background-red.svg"
                           alt='background-red' className="background"/>
                      <img src="/messages/circle-remove.svg"
                           style={{width: 16, height: 16}} alt='circle-remove'
                           className="level-image"/>
                    </Col>
                    <Col xs={10}>
                      <span className="level"> <>{t(
                          'message.messages.errorMessageText')}</> </span>: {message}
                    </Col>
                  </Row>
                </div>
        }
      </div>
  );

};

export default Message;
