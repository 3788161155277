import React, {ChangeEvent, useCallback, useState} from 'react';
import conf from '../config';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import util from "../util";
import {useTranslation} from 'react-i18next';
import {AxiosResponse} from "axios";
import {CompanyDto} from "./Companies";
import {Token} from "../types/token";


export interface FilterCompanyProps {
  updateCompanyListElements: (pageNumber: number, queryParam: string) => void,
  token: Token,
  statusFilterVal: string,
  toggleCheckedButton: (value: string) => void,
}

const FilterCompany = ({
                         updateCompanyListElements,
                         token, statusFilterVal, toggleCheckedButton
                       }: FilterCompanyProps) => {

  const {t} = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [filterByCompanyResult, setFilterByCompanyResult] = useState<CompanyDto[]>([]); // Search Company Result
  const [filterByCompany, setFilterByCompany] = useState(""); // Input text

  const handleFilterByCompanyInputChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    setFilterByCompany(value);
    updateFilterByCompanyResult(value);
  }

  const handleShow = () => {
    setShowFilter(true);
  }
  const updateFilterByCompanyResult = useCallback((value: string) => {
    const url = conf.urls.serviceUrl + conf.urls.orderOverviewCompanyAPI + "?name=" + value;
    util.serviceCallWrapper({
          method: "GET",
          url: url,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          if (result.data.content !== '' && result.data.content !== undefined) {
            let companies: CompanyDto[] = result.data.content.map((companyIn: CompanyDto) => {
              return {
                id: companyIn.id,
                code: companyIn.code,
                name: companyIn.name,
                assemblyHourlyRate: companyIn.assemblyHourlyRate,
                internalAssemblyHourlyRate: companyIn.internalAssemblyHourlyRate,
                status: companyIn.status,
                catalogCodes: companyIn.catalogCodes,
                suissetecMember: companyIn.suissetecMember,
                reseller: companyIn.reseller
              }
            });
            setFilterByCompanyResult(companies);
          }
        },
        {},
        () => {
        },
        false
    );
  }, [token]);


  const applyFilters = () => {
    updateCompanyListElements(0, "&name=" + filterByCompany);
    toggleCheckedButton("ALL")
    setFilterByCompany('');
    setShowFilter(false);
  }

  const handleClose = () => {
    setShowFilter(false);
    setFilterByCompany('');
  };


  return (
      <div>
        <Button className="filter" onClick={() => (handleShow())}>
          <img src="/common-icons/search.svg"
               style={{width: 18, height: 18}}
               alt=''/>
          <span
              className="value"> <> {t('company.companyMain.backofficeTableFilter.value')} </> </span>
        </Button>

        <Modal show={showFilter} onHide={handleClose} size="lg"
               dialogClassName="modal-orderInfo">
          <Modal.Header closeButton>
            <Modal.Title className="content"><>{t(
                'existingOrder.existingOrder.filter.modal.title')}</>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="modalFilterBody">
              <Col>
                <Row className="title">
                  <>{t('company.companyMain.filter.modal.company')}</>
                </Row>
                <Row>
                  <input className="companyFilter" name="companyFilter"
                         value={filterByCompany}
                         onChange={handleFilterByCompanyInputChange}
                  />
                </Row>

              </Col>
            </Container>

          </Modal.Body>
          <Modal.Footer>
            <Button className="closeView" variant="secondary"
                    onClick={handleClose}>
              <>{t('existingOrder.existingOrder.filter.modal.close')}</>
            </Button>

            <Button className="closeView" variant="secondary"
                    onClick={applyFilters}>
              <>{t('existingOrder.existingOrder.filter.modal.applyFilters')}</>
            </Button>

          </Modal.Footer>
        </Modal>

      </div>
  );
}

export default FilterCompany;
