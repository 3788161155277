import React from 'react';
import axios, {AxiosRequestConfig} from 'axios';
import {toast} from 'react-toastify';
import Message from './message/Message';
import conf from './config';
import {Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import moment from "moment";
import {DiameterNominalEnum} from "./generated/api/ineexaApi";

const util = {

  /**
   * serviceCallWrapper
   * @param  {Object} axiosConfig configuration to perform axios API call
   * @param  {function} processServiceCallResponse function to process the response of the axios call to service
   * @param {Object } messageConfig HTTP response statuses returned by the axios call to service, accompanied with message levels and text content
   * @param {function} processServiceCallError function that is called when the error in the axios call occurs
   * @param {boolean} showErrorMessages boolean value that specifies whether the error messages should be shown
   * @return {void} function does not return any value
   *
   * Example of serviceCallWrapper call
   * serviceCallWrapper({
          method: 'PATCH',
          url: https://...,
          data: someObject,
          headers: accessToken
              ? {Authorization: `Bearer ${accessToken}`} : {},
        },
   (result) => {someFunctionThatProcessCallResult(result)},
   {
            200: {
                  'SUCCESS': 'Item quantity is changed!'
                 },
            404: {
                  'ERROR': 'Item not found.'
                },
        },
   () => {someFunctionThatIsCalledOnError()}
   );
   */
  serviceCallWrapper: function (axiosConfig: AxiosRequestConfig, processServiceCallResponse: Function,
      messageConfig: any, processServiceCallError: Function, showErrorMessages : boolean = true ) {
    axios(axiosConfig)
    .then(result => {
      processServiceCallResponse(result)
      if (Object(messageConfig).hasOwnProperty(result.status)) {
        toast(<Message level={Object.keys(messageConfig[result.status])[0]}
                       message={Object.values(
                           messageConfig[result.status])[0] as string}/>,
            conf.messageOptionsSuccess);
      }
    })
    .catch(error => {
      if (processServiceCallError !== undefined) {
        processServiceCallError();
      }

      if (showErrorMessages === true) {
        if (error.response.status !== undefined && Object(
            messageConfig).hasOwnProperty(error.response.status)) {
          toast(<Message
                  level={Object.keys(messageConfig[error.response.status])[0]}
                  message={Object.values(
                      messageConfig[error.response.status])[0] as string}/>,
              conf.messageOptionsOther);
        } else {
          if (error.response.data.message) {
            toast(<Message level="ERROR"
                           message={error.response.data.message}/>,
                conf.messageOptionsOther);
          } else {
            toast.error(<Message level="ERROR"
                                 message="Something went wrong. Please try again."/>,
                conf.messageOptionsOther);
          }
        }
      }
    })

  },
  displayTime(time: string | undefined, formatting: string) {
    return moment(time).format(formatting)
  },

  displayList(list: string[] | undefined, delimiter: string) {
      var result = "";
      if (list != undefined) {
        var listSize = list.length;
        list.forEach((elem, index) => {
          result = result + elem;
          if (index != listSize - 1) {
            result = result + `${delimiter} `;
          }
        })
      }
      return result;
  },

  displayDiameterNominals(list: DiameterNominalEnum[] | undefined, delimiter: string) {
      var result = "";
      if (list != undefined) {
        var listSize = list.length;
        list.forEach((elem, index) => {
          result = result + elem;
          if (index != listSize - 1) {
            result = result + `${delimiter} `;
          }
        })
      }
      return result;
  }

};

interface UuidPopoverProps {
  shortId: string | undefined;
  longId: string | undefined;
}

export const UuidPopover : React.FC<UuidPopoverProps> = (props: UuidPopoverProps) => {
    return (
        <Col>
          <Row>
            <OverlayTrigger
                placement="bottom"
                trigger={["click", "focus"]}
                overlay={(
                    <Popover
                        style={{width: 400}}>
                      <Popover.Body>
                        {props.longId}
                      </Popover.Body>
                    </Popover>
                )}>
              <div>
                {props.shortId}
              </div>
            </OverlayTrigger>
          </Row>
        </Col>
    );
  }


export default util;

