import React, {useEffect, useState, useCallback} from "react";
import {useSelector} from "react-redux";
import conf from "../config";
import {
  Container,
  Row,
  Col,
  Pagination,
  Spinner,
} from 'react-bootstrap';
import CustomerChangesEntry from "./CustomerChangesEntry";
import util from "../util";
import {useTranslation} from 'react-i18next';
import {Trans} from 'react-i18next';
import {Token} from "../types/token";
import {AxiosResponse} from "axios";
import {CustomerChange} from "../generated/api/ineexaApi";
import {useParams} from "react-router-dom";

const CustomerChanges = () => {

  const {t} = useTranslation();
  const [customerChanges, setCustomerChanges] = useState<CustomerChange[]>([]);
  let { version } : any = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [paginationItems, setPaginationItems] = useState<Element[]>([]);
  const token: Token = useSelector((state: any) => state.addAuthData);

  const updateCustomerChangesListElements = useCallback((pageNumber: number) => {
    const url = `${conf.urls.serviceUrl}${conf.urls.orderOverviewAPI}/${version}/customerchange?customerChangeType=MANUAL&sort=pageIndex,asc&sort=productGroupIndex,asc&page=${pageNumber}&size=10`;
    util.serviceCallWrapper({
          method: "GET",
          url: url,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,

          }
        },
        (result: AxiosResponse) => {
          if (result.data !== '' && result.data !== undefined && result.data.content !== undefined) {
            setCustomerChanges(result.data.content);
            setTotalPages(result.data.totalPages > 0 ? result.data.totalPages - 1 : 0);
          }
        },
        {},
        () => {
        },
        false
    );
  }, [token]);

  const openPage = useCallback((pageNumber: number) => {
    updateCustomerChangesListElements(pageNumber);
    setCurrentPage(pageNumber);
  }, [updateCustomerChangesListElements]);

  const createPagination = useCallback(() => {
    let pages: any[] = [];
    for (let number = 0; number <= totalPages; number++) {
      pages.push(
          <Pagination.Item key={number} active={number == currentPage}
                           onClick={() => openPage(number)}>
            {number + 1}
          </Pagination.Item>,
      );
      setPaginationItems(pages);
    }
  }, [openPage, currentPage, totalPages]);

  useEffect(() => {
    document.title = t('customerChanges.mainTitle');
    updateCustomerChangesListElements(currentPage);
    createPagination();
  }, []);

  useEffect(() => {
    createPagination();
  }, [updateCustomerChangesListElements, createPagination, currentPage, totalPages, token]);

  const openFirstPage = () => {
    updateCustomerChangesListElements(0);
    setCurrentPage(0);
  };

  const openLastPage = () => {
    updateCustomerChangesListElements(totalPages);
    setCurrentPage(totalPages);
  };

  const openNextPage = () => {
    if (currentPage < totalPages) {
      updateCustomerChangesListElements(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const openPreviousPage = () => {
    if (currentPage > 0) {
      updateCustomerChangesListElements(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const renderCustomerChange = (customerChange: CustomerChange) => {
    return (<CustomerChangesEntry key={customerChange.id} customerChange={customerChange}/>);
  };

  return (
      <div>
        <Row>
          <Col xl={1} className="hidden-lg"/>
          <Col xl={10} className="mainContent">
            <Container fluid className="orders">
              {(token.authenticated) ? (
                      (token.isAdminOrder) ?

                          <div>
                            <Row className="pageName">
                              <Col xs={{span: 12}} className="label">
                                <Trans
                                    i18nKey="customerChanges.mainView.label" // optional -> fallbacks to defaults if not provided
                                    defaults="Customer Changes -  Overview" // optional defaultValue
                                    components={{tag: <span/>}}
                                />
                              </Col>

                            </Row>
                            <Row className="tableHeader">
                              <Col xs={1} className="label">
                                <>{t('customerChanges.tableHeader.pageNumber.label')}</>
                              </Col>
                              <Col xs={2} className="label">
                                <>{t('customerChanges.tableHeader.oldProduct.label')}</>
                              </Col>
                              <Col xs={2} className="label">
                                <>{t('customerChanges.tableHeader.newProduct.label')}</>
                              </Col>
                              <Col xs={2} className="label">
                                <>{t('customerChanges.tableHeader.lastParseDate.label')}</>
                              </Col>
                              <Col xs={3} className="label">
                                <>{t('customerChanges.tableHeader.changeReasonAnswer.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('customerChanges.tableHeader.reviewedInBackoffice.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('customerChanges.tableHeader.action.label')}</>
                              </Col>
                            </Row>
                            {customerChanges.map((customerChange) => renderCustomerChange(customerChange))}
                            <Row className="paging">
                              <Col xs={5} className="content"/>
                              <Col xs={7} className="content">
                                <Pagination className="buttons">
                                  <Pagination.First onClick={openFirstPage}/>
                                  <Pagination.Prev onClick={openPreviousPage}/>
                                  <Pagination><>{paginationItems}</>
                                  </Pagination>
                                  <Pagination.Next onClick={openNextPage}/>
                                  <Pagination.Last onClick={openLastPage}/>
                                </Pagination>
                              </Col>
                              <Col xs={2}/>
                            </Row>
                          </div>
                          :
                          <div className='messageLogout'>

                            <>{t('order.ordersMain.messageLogout.messageText')}</>

                          </div>)
                  :
                  <div className='spinner'>
                    <Spinner animation="border" role="status" size="sm"/>{" "}Authenticating
                  </div>
              }

            </Container>
          </Col>
          <Col xl={1} className="hidden-lg"/>
        </Row>
      </div>
  );

};

export default CustomerChanges;