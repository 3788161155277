import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createStore} from "redux";
import {Provider} from "react-redux";
import reducer from "./reducers";
import conf from "./config";
import MainPage from "./main/MainPage";
import {AuthProvider} from "oidc-react";
import "./i18n/i18n";

const store = createStore(
    reducer);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

/* global document */
root.render(
    <Provider store={store}>
      <AuthProvider {...conf.oidcConfig}>
        <MainPage/>
      </AuthProvider>
    </Provider>);
