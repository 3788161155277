import React, {useEffect, useState, useCallback, ChangeEvent} from "react";
import {useSelector} from "react-redux";
import conf from "../config";
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Spinner,
  Modal, Form, Badge
} from 'react-bootstrap';
import ExistingOrderOverview from "./ExistingOrderOverview";
import util from "../util";
import {useTranslation} from 'react-i18next';
import {Trans} from 'react-i18next';
import {Token} from "../types/token";
import {AxiosResponse} from "axios";
import {Company, BackofficeVersion, VersionStatus} from "../generated/api/ineexaApi";

export interface VersionStatusFilter {
  INITIAL: boolean,
  SAVED: boolean,
  PARSING: boolean,
  MANUAL_PARSING: boolean,
  READY: boolean,
  SUBMITTED: boolean,
  CLOSED: boolean,
  ERROR: boolean,
  ARCHIVED: boolean,
}

const OrderOverview = () => {

  const {t} = useTranslation();
  const [orders, setOrders] = useState<BackofficeVersion[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showSelectedFilters, setShowSelectedFilters] = useState(false);
  const [paginationItems, setPaginationItems] = useState<Element[]>([]);
  const [filterByCompany, setFilterByCompany] = useState(""); // Input text
  const [filterByCompanyResult, setFilterByCompanyResult] = useState<Company[]>([]); // Search Company Result
  const token: Token = useSelector((state: any) => state.addAuthData);
  const [checkedButton, setCheckedButton] = useState("all")
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(); // Selected Company from the list
  const [filterByVersionStatus, setFilterByVersionStatus] = useState<VersionStatusFilter>({
    INITIAL: false,
    SAVED: false,
    PARSING: false,
    MANUAL_PARSING: false,
    READY: false,
    SUBMITTED: false,
    CLOSED: false,
    ERROR: false,
    ARCHIVED: false,
  });

  const updateOrderListElements = useCallback((pageNumber: number, queryParameterProp?: string) => {
    const queryParameter = queryParameterProp !== undefined ? queryParameterProp : "";
    const url = conf.urls.serviceUrl + conf.urls.orderOverviewAPI + "?page=" + pageNumber + "&size=10" + queryParameter;
    util.serviceCallWrapper({
          method: "GET",
          url: url,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          if (result.data !== '' && result.data !== undefined
              && result.data.content !== undefined) {
            let orders: BackofficeVersion[] = result.data.content.map((orderIn: BackofficeVersion) => {
              return {
                id: orderIn.id,
                created: orderIn.created,
                modified: orderIn.modified,
                name: orderIn.name,
                status: orderIn.status,
                user: orderIn.user,
                order: orderIn.order,
                pageCount: orderIn.pageCount,
                productCount: orderIn.productCount,
                reviewedCustomerChangesCount: orderIn.reviewedCustomerChangesCount,
                totalCustomerChangesCount: orderIn.totalCustomerChangesCount
              }
            });
            setOrders(orders);
            setTotalPages(result.data.totalPages > 0 ? result.data.totalPages - 1 : 0);
          }
        },
        {},
        () => {
        },
        false
    );
  }, [token]);

  const openPage = useCallback((pageNumber: number) => {
    updateOrderListElements(pageNumber);
    setCurrentPage(pageNumber);
  }, [updateOrderListElements]);

  const createPagination = useCallback(() => {
    let pages: any[] = [];
    for (let number = 0; number <= totalPages; number++) {
      pages.push(
          <Pagination.Item key={number} active={number === currentPage}
                           onClick={() => openPage(number)}>
            {number + 1}
          </Pagination.Item>,
      );
      setPaginationItems(pages);
    }
  }, [openPage, currentPage, totalPages]);

  useEffect(() => {
    document.title = t('existingOrder.orderMain.orderMainTitle');
    updateOrderListElements(currentPage);
    createPagination();
  }, [filterByCompany]);

  useEffect(() => {
    createPagination();
  }, [updateOrderListElements, createPagination, currentPage, totalPages, token]);

  const openFirstPage = () => {
    updateOrderListElements(0);
    setCurrentPage(0);
  };

  const openLastPage = () => {
    updateOrderListElements(totalPages);
    setCurrentPage(totalPages);
  };

  const openNextPage = () => {
    if (currentPage < totalPages) {
      updateOrderListElements(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const openPreviousPage = () => {
    if (currentPage > 0) {
      updateOrderListElements(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const handleClose = () => setShowFilter(false);

  const handleShow = () => {
    setShowFilter(true);
  }

  const handleFilterByCompanyInputChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    setFilterByCompany(value);
    updateFilterByCompanyResult(value);
  }

  const removeCompanyFilter = () => {
    setFilterByCompany("")
    setShowSelectedFilters(false);
    setFilterByCompanyResult([]);
    setSelectedCompany(undefined);
  }

  const updateFilterByCompanyResult = useCallback((value: string) => {
    const url = conf.urls.serviceUrl + conf.urls.orderOverviewCompanyAPI + "/search?text=" + value;
    util.serviceCallWrapper({
          method: "GET",
          url: url,
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          }
        },
        (result: AxiosResponse) => {
          if (result.data !== '' && result.data !== undefined) {
            let companies: Company[] = result.data.map((company: Company) => {
              return {
                id: company.id,
                code: company.code,
                name: company.name,
              }
            });
            setFilterByCompanyResult(companies);
          }
        },
        {},
        () => {
        },
        false
    );
  }, [token]);


  const handleFilterByStatusCheckboxChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const name = target.name;
    const value = filterByVersionStatus[name as keyof typeof VersionStatus];

    setFilterByVersionStatus(prevState => ({
      ...prevState,
      [name]: !value,
    }));
  }

  const handleFilterByCompanyCheckboxChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>, company: Company) => {
    selectedCompany === company ? setSelectedCompany(undefined) : setSelectedCompany(company);
  }

  const applyFilters = () => {
    // logic for version status filters. Can be used when the API supports versionStatus
    var versionFilter = "";
    Object.keys(filterByVersionStatus).forEach((keyName, keyIndex) => {
      var isChecked = filterByVersionStatus[keyName as keyof typeof VersionStatus];
      if (isChecked) {
        versionFilter += "&versionStatus=" + keyName
      }
    });

    if (selectedCompany !== undefined) {
      updateOrderListElements(0, "&companyCode=" + selectedCompany?.code);
      setShowSelectedFilters(true);
    }
    setShowFilter(false);
  }

  const renderOrder = (order: BackofficeVersion) => {
    return (<ExistingOrderOverview key={order.id} order={order}/>);
  };

  return (
      <div>
        <Row>
          <Col xl={1} className="hidden-lg"/>
          <Col xl={10} className="mainContent">
            <Container fluid className="orders">
              {(token.authenticated) ? (
                      (token.isAdminOrder) ?

                          <div>
                            <Row className="pageName">
                              <Col xs={{span: 12}} className="label">
                                <Trans
                                    i18nKey="existingOrder.orderMain.label" // optional -> fallbacks to defaults if not provided
                                    defaults="Devi -  Overview" // optional defaultValue
                                    components={{tag: <span/>}}
                                />
                              </Col>

                            </Row>
                            <Row className="backofficeTable">
                              <Col xs={3} className="tab">
                                <Button className={checkedButton === "all"
                                    ? "button active" : "button"} onClick={() => {
                                  updateOrderListElements(0);
                                  setCurrentPage(0);
                                  setCheckedButton("all")
                                }}><>{t(
                                    'existingOrder.orderMain.backofficeTableTab.all')}</>
                                </Button>

                                <Button className={checkedButton === "manual_parsing"
                                    ? "button active" : "button"} onClick={() => {
                                  updateOrderListElements(0, "&statusValue=" + VersionStatus.MANUAL_PARSING);
                                  setCurrentPage(0);
                                  setCheckedButton("manual_parsing")
                                }}><>{t(
                                    'existingOrder.orderMain.backofficeTableTab.manualParsing')}</>
                                </Button>
                              </Col>
                              <Col xs={8} className="companyFilterTab">
                                {showSelectedFilters ?
                                    <Badge bg="Primary" className="company">
                                      <Row>
                                        <Col className="name">
                                          {selectedCompany?.name}
                                        </Col>
                                        <Col className="removeFilter">
                                          <Button variant="link" className="remove"
                                                  onClick={removeCompanyFilter}>X</Button>
                                        </Col>
                                      </Row>
                                    </Badge>
                                    :
                                    ""
                                }

                              </Col>
                              <Col xs={1} className="features">
                                <Button className="filter" onClick={handleShow}>
                                  <img src="/common-icons/search.svg"
                                       style={{width: 18, height: 18}}
                                       alt=''/>
                                  <span className="value"> <>{t(
                                      'existingOrder.orderMain.backofficeTableFilter.value')}</> </span>
                                </Button>
                              </Col>
                            </Row>
                            <Row className="tableHeader">
                              <Col xs={2} className="label">
                                <>{t('existingOrder.orderMain.tableHeader.company.label')}</>
                              </Col>
                              <Col xs={2} className="label">
                                <>{t('existingOrder.orderMain.tableHeader.order.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('existingOrder.orderMain.tableHeader.pages.label')}</>
                              </Col>
                              <Col xs={2} className="label">
                                <>{t('existingOrder.orderMain.tableHeader.products.label')}</>
                              </Col>
                              <Col xs={2} className="label">
                                <>{t('existingOrder.orderMain.tableHeader.date.label')}</>
                              </Col>
                              <Col xs={1} className="label">
                                <>{t('existingOrder.orderMain.tableHeader.reviewed.changes.label')}</>
                              </Col>
                              {/*<Col xs={1} className="label"/>*/}
                              <Col xs={2} className="label">
                                <>{t('existingOrder.orderMain.tableHeader.action.label')}</>
                              </Col>
                            </Row>
                            {orders.map((order) => renderOrder(order))}
                            <Row className="paging">
                              <Col xs={5} className="content"/>
                              <Col xs={7} className="content">
                                <Pagination className="buttons">
                                  <Pagination.First onClick={openFirstPage}/>
                                  <Pagination.Prev onClick={openPreviousPage}/>
                                  <Pagination><>{paginationItems}</>
                                  </Pagination>
                                  <Pagination.Next onClick={openNextPage}/>
                                  <Pagination.Last onClick={openLastPage}/>
                                </Pagination>
                              </Col>
                              <Col xs={2}/>
                            </Row>
                          </div>
                          :
                          <div className='messageLogout'>

                            <>{t('order.ordersMain.messageLogout.messageText')}</>

                          </div>)
                  :
                  <div className='spinner'>
                    <Spinner animation="border" role="status" size="sm"/>{" "}Authenticating
                  </div>
              }

            </Container>
          </Col>
          <Col xl={1} className="hidden-lg"/>
        </Row>

        <Modal show={showFilter} onHide={handleClose} size="lg"
               dialogClassName="modal-orderInfo">
          <Modal.Header closeButton>
            <Modal.Title className="content"><>{t(
                'existingOrder.existingOrder.filter.modal.title')}</>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="modalFilterBody">
              <Col>
                <Row className="title">
                  <>{t('existingOrder.existingOrder.filter.modal.company')}</>
                </Row>
                <Row>
                  <input className="companyFilter" name="companyFilter"
                         value={filterByCompany}
                         onChange={handleFilterByCompanyInputChange}/>
                </Row>

                <Row>
                  <Form>
                    {
                      filterByCompanyResult.map((company, companyIndex) => (
                          <div key={`inline-${company.code}`} className="mb-3">
                            <Form.Check
                                inline
                                label={company.name}
                                name="group1"
                                type="radio"
                                id={company.id}
                                onChange={(event) => handleFilterByCompanyCheckboxChange(event, company)}
                            />
                          </div>
                      ))}
                  </Form>
                </Row>
              </Col>


              <Col>
                <Row className="title">
                  <>{t('existingOrder.existingOrder.filter.modal.status')}</>
                </Row>
                <Row>
                  <Form>
                    {

                      Object.keys(filterByVersionStatus).map((keyName, keyIndex) => (
                          <div key={`inline-${keyName}`} className="mb-3">
                            <Form.Check
                                inline
                                label={keyName}
                                name={keyName}
                                type="checkbox"
                                id={keyName}
                                onChange={(event) => handleFilterByStatusCheckboxChange(event)}
                            />
                          </div>
                      ))}
                  </Form>
                </Row>
              </Col>
            </Container>

          </Modal.Body>
          <Modal.Footer>
            <Button className="closeView" variant="secondary"
                    onClick={handleClose}>
              <>{t('existingOrder.existingOrder.filter.modal.close')}</>
            </Button>

            <Button className="closeView" variant="secondary"
                    onClick={applyFilters}>
              <>{t('existingOrder.existingOrder.filter.modal.applyFilters')}</>
            </Button>

          </Modal.Footer>
        </Modal>
      </div>
  );

};

export default OrderOverview;