import {LANGUAGE} from "../actionTypes";
import {setCurrentLanguageResponse} from "../actions/language";
import {Language} from "../types/language";

const initialState = {
  label: "English",
  value: "EN",
  code: "en"
}

export default (state: Language = initialState, action: setCurrentLanguageResponse) : Language => {
  switch (action.type) {
    case LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}
