import React from "react";
import {Row, Col, Button, Form} from "react-bootstrap";
import conf from "../config";
import util from "../util";
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {CustomerChange} from "../generated/api/ineexaApi";

export interface ExistingCustomerChangeProps {
  customerChange: CustomerChange,
}

const CustomerChangesEntry = ({customerChange} : ExistingCustomerChangeProps) => {

  const {t} = useTranslation();
  const history = useHistory();

  const redirectToCustomerChangesAnalysis = () => {
    history.push(`${history.location.pathname}/customer-change-analysis/${customerChange.id}`);
  };

  return (
      <div>
        <Row className="tableContent">
          <Col xs={1} className="value">
            <Row>
              {customerChange.pageIndex}
            </Row>
          </Col>
          <Col xs={2} className="value">
            {customerChange.oldProduct?.title ?
              `${customerChange.oldProduct?.title} (${customerChange.oldProduct?.catalogName} - ${customerChange.oldProduct?.catalogArticleNumber})`
                :
              ""
            }
          </Col>
          <Col xs={2} className="value">
            {customerChange.newProduct?.title ?
              `${customerChange.newProduct?.title} (${customerChange.newProduct?.catalogName} - ${customerChange.newProduct?.catalogArticleNumber})`
                :
              ""
            }
          </Col>
          <Col xs={2} className="value">
            {util.displayTime(customerChange.lastParsingDate, conf.formatting.dateTime)}
          </Col>
          <Col xs={3} className="value">
            {customerChange.question2AnswerMap
                ?
                  util.displayList(Object.values(customerChange.question2AnswerMap).map((answer) => answer.toUpperCase()), ",")
                :
                  ""
            }
          </Col>
          <Col xs={1} className="value">
            <Form.Check
              disabled={true}
              inline
              name="group1"
              type="checkbox"
              id={customerChange.id}
              defaultChecked={customerChange.reviewed}
            />
          </Col>
          <Col xs={1} xl={1} className="value">
            <Button className="function-button" onClick={redirectToCustomerChangesAnalysis}>
              <p> <>{t('customerChanges.customerChangesEntry.analyze.value')}</> </p>
            </Button>
          </Col>
          <Col xs={2}>
          </Col>
        </Row>
      </div>
  );

};

export default CustomerChangesEntry;
