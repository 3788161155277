import React from "react";
import {Row, Col} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {ExtendedCustomerChange} from "../generated/api/ineexaApi";
import util from "../util";

export interface ExistingProductComparisonProps {
  extendedCustomerChange: ExtendedCustomerChange | undefined,
}

const ParsedData = ({extendedCustomerChange} : ExistingProductComparisonProps) => {

  const {t} = useTranslation();

  return (
      <div>
        <Row className="tableHeader">
          <Col xs={12} className="label left">
            <>{t('customerChangeAnalysis.tableHeader.parsedData.label')}</>
          </Col>
        </Row>

        <Row className="tableContent">
          <Col xs={1} className="value">
            <>{t('customerChangeAnalysis.tableContent.parsedData.manufacturer.label')}</>
          </Col>
          <Col xs={11} className="value">
            {extendedCustomerChange?.productGroup.manufacturer}
          </Col>
        </Row>

        <Row className="tableContent">
          <Col xs={1} className="value">
            <>{t('customerChangeAnalysis.tableContent.parsedData.name.label')}</>
          </Col>
          <Col xs={11} className="value">
            {extendedCustomerChange?.productGroup.name}
          </Col>
        </Row>

        <Row className="tableContent">
          <Col xs={1} className="value">
            <>{t('customerChangeAnalysis.tableContent.parsedData.originalText.label')}</>
          </Col>
          <Col xs={11} className="value originalText">
            {extendedCustomerChange?.product.originalText}
          </Col>
        </Row>

        <Row className="tableContent">
          <Col xs={1} className="value">
            <>{t('customerChangeAnalysis.tableContent.parsedData.DN.label')}</>
          </Col>
          <Col xs={11} className="value">
            {util.displayDiameterNominals(extendedCustomerChange?.product.diameterNominals, ",")}
          </Col>
        </Row>
      </div>
  );

};

export default ParsedData;
