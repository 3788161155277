import React from "react";
import {Row, Col} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {ExtendedCustomerChange} from "../generated/api/ineexaApi";

export interface ExistingProductComparisonProps {
  extendedCustomerChange: ExtendedCustomerChange | undefined,
}

const ProductComparison = ({extendedCustomerChange} : ExistingProductComparisonProps) => {

  const {t} = useTranslation();

  return (
      <div>
        <Row className="tableHeader">
          <Col xs={1} className="label" />
          <Col xs={5} className="label">
            <>{t('customerChangeAnalysis.tableHeader.oldProduct.label')}</>
          </Col>
          <Col xs={6} className="label">
            <>{t('customerChangeAnalysis.tableHeader.newProduct.label')}</>
          </Col>
        </Row>

        <Row className="tableContent">
          <Col xs={1} className="value">
            <>{t('customerChangeAnalysis.tableContent.title.label')}</>
          </Col>
          <Col xs={5} className="value">
            {extendedCustomerChange?.customerChange.oldProduct?.title}
          </Col>
          <Col xs={6} className="value">
            {extendedCustomerChange?.customerChange.newProduct?.title}
          </Col>
        </Row>

        <Row className="tableContent">
          <Col xs={1} className="value">
            <>{t('customerChangeAnalysis.tableContent.description.label')}</>
          </Col>
          <Col xs={5} className="value description">
            {extendedCustomerChange?.customerChange.oldProduct?.description}
          </Col>
          <Col xs={6} className="value description">
            {extendedCustomerChange?.customerChange.newProduct?.description}
          </Col>
        </Row>

        <Row className="tableContent">
          <Col xs={1} className="value">
            <>{t('customerChangeAnalysis.tableContent.articleNumber.label')}</>
          </Col>
          <Col xs={5} className="value">
            {extendedCustomerChange?.customerChange.oldProduct?.catalogArticleNumber}
          </Col>
          <Col xs={6} className="value">
            {extendedCustomerChange?.customerChange.newProduct?.catalogArticleNumber}
          </Col>
        </Row>

        <Row className="tableContent">
          <Col xs={1} className="value">
            <>{t('customerChangeAnalysis.tableContent.catalog.label')}</>
          </Col>
          <Col xs={5} className="value">
            {extendedCustomerChange?.customerChange.oldProduct?.catalogName}
          </Col>
          <Col xs={6} className="value">
            {extendedCustomerChange?.customerChange.newProduct?.catalogName}
          </Col>
        </Row>
      </div>
  );

};

export default ProductComparison;
