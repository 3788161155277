import React from "react";
import {Col, Row} from "react-bootstrap";
import {MappingType, Synonym} from "../generated/api/ineexaApi";
import util from "../util";

export interface SynonymProps {
  synonym: Synonym | undefined,
}

const MatchingSynonym = ({synonym} : SynonymProps) => {


  return (
        <Row className="tableContent">
        {
          synonym && synonym.mappingType === MappingType.Equivalent ?
              <Col xs={12} className="value left">
                Equivalent: {util.displayList(synonym.synonyms, ",")}
              </Col>
              :
              <Col xs={12} className="value left">
                Explicit: {util.displayList(synonym?.input, ",")} {`->`} {util.displayList(synonym?.synonyms, ",")}
              </Col>
        }
        </Row>
  );

};

export default MatchingSynonym;
