import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {CatalogCodeDto, CompanyDto} from './Companies'
import {Trans} from "react-i18next";

export interface MultiSelectDropdownProps {
  catalogCodes: CatalogCodeDto[],
  defaultValues: string [],
  status: string,
  company: CompanyDto,
}

// Model used for react select control
export interface MultiSelectModel {
  value: string,
  label: string,
}

const CatalogCodesMultiSelect = ({
                                   catalogCodes,
                                   defaultValues,
                                   company,
                                 }: MultiSelectDropdownProps) => {

  const [codesAvailableForUpdate, setCodesAvailableForUpdate] = useState<MultiSelectModel[]>([]);
  const [currentCatalogCodes, setCurrentCatalogCodes] = useState<MultiSelectModel[]>([]);

  function updateCurrentCatalogCodes() {
    let currentCatalogCodesTmp: MultiSelectModel[] = [];
    for (let i = 0; i < defaultValues.length; i++) {
      currentCatalogCodesTmp.push({
        value: defaultValues[i],
        label: getNameFromCode(defaultValues[i])
      });
    }
    currentCatalogCodesTmp.sort((a, b) => (a.label < b.label ? -1 : 1));
    setCurrentCatalogCodes(currentCatalogCodesTmp);
  }

  useEffect(() => {
    updateCurrentCatalogCodes();
  }, [catalogCodes, defaultValues]);

  function updateCodesAvailableForUpdate() {
    let codesNotSelected: MultiSelectModel[] = [];
    for (let i = 0; i < catalogCodes.length; i++) {
      let catalog = catalogCodes[i];
      if (!defaultValues.includes(catalog.code)) { // if code already assigned to company, skip that code
        codesNotSelected.push({value: catalog.code, label: catalog.name});
      }
    }
    setCodesAvailableForUpdate(codesNotSelected); // All catalog codes - existing codes of the company
  }

  // Type for allOptions is CatalogCodeDto[]
  useEffect(() => {
    updateCodesAvailableForUpdate();
  }, [catalogCodes, defaultValues]);

  function handleSelect(data: any) {
    // set codes in company object to, later used to update company
    company.catalogCodes = getCodesFromSelectedCatalogs(data);
    defaultValues = company.catalogCodes
    updateCurrentCatalogCodes();
    updateCodesAvailableForUpdate();
  }

  const getCodesFromSelectedCatalogs = (selectBoxValues: MultiSelectModel[]) => {
    let updatedCodes = [];
    for (var option of selectBoxValues) {
      updatedCodes.push(option.value);
    }
    return updatedCodes;
  }

  const getNameFromCode = (code: string) => {
    for(let i =0; i < catalogCodes.length ; i++){
      if(code === catalogCodes[i].code)
        return catalogCodes[i].name;
    }
    return '';
  }

  return (

      <div className="app w-100">
            <Select
                id='codes'
                value={currentCatalogCodes}
                options={codesAvailableForUpdate}
                placeholder={<Trans
                    i18nKey={'company.companyMain.backofficeSelectBox.placeholder'}/>}
                onChange={handleSelect}
                isSearchable={true}
                isMulti
                isDisabled={company.status === 'DELETED' ? true : undefined}
            />
      </div>
  );
};

export default CatalogCodesMultiSelect;
