import React from 'react';
import {
  Form,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
  Image,
  Row,
  Col
} from "react-bootstrap";
import conf from "../config";
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Token} from "../types/token";
import LanguageSwitch from "./LanguageSwitch";

interface HeaderProps {
  logout: () => void,
}

const Header = ({logout} : HeaderProps) => {
  const {t} = useTranslation();
  const token: Token = useSelector((state: any) => state.addAuthData);

  return (
      <Navbar expand="lg">
        <div className="header">
          <Row>
            <Col md="auto">

              <div className="logo">
                <Navbar.Brand>
                  <a href={conf.urls.backoffice_main}>
                    <img src="/header-assets/ineexa.svg" style={{width: 120, height: 120}} alt=''/>
                  </a>
                  <Navbar.Toggle/>
                </Navbar.Brand>
              </div>
            </Col>

            <Navbar.Collapse id="basic-navbar-nav ml-auto">
              <Col md={9}>
                <div>
                  <Form className="textHolder">
                    <FormControl type="text" placeholder={t(
                        'main.header.textHolder.placeholder')}
                                 className="search"
                    />
                  </Form>
                </div>
              </Col>

              <Col md={3}>
                <div className="infoBar">

                  <div className="icons">
                      <LanguageSwitch/>
                    <a className="help disabled">
                      <img src="/menu-icons/help-circle.svg" alt=''/>
                    </a>
                    <a className="task disabled">
                      <img src="/menu-icons/task-list.svg" alt=''/>
                    </a>
                    <a className="notification disabled">
                      <img src="/menu-icons/notifications.svg" alt=''/>
                    </a>
                    <img className="verticalLine"
                         src="menu-icons/vertical-line.png" alt=''/>

                  </div>

                  <div className="login">
                    <Nav className="navs">
                      {(token.authenticated) ?
                          (
                              <NavDropdown title={token && token.userInfo ? token.userInfo.preferred_username : "User"}
                                           id="basic-nav-dropdown"
                                           className="dropdown">
                                <NavDropdown.Divider/>
                                <NavDropdown.Item
                                    onClick={logout}><>{t(
                                    'main.header.login.logoutText')}</></NavDropdown.Item>
                              </NavDropdown>
                          )
                          :
                          <Navbar.Text className="entrySpinner">
                            <img src="/header-assets/spinner.gif"
                                 style={{width: 35, height: 35}}
                                 alt=''/>
                          </Navbar.Text>
                      }
                      <div className="icons">
                        <Image src="/header-assets/account_circle.svg"
                               roundedCircle/>
                      </div>
                    </Nav>
                  </div>
                </div>

              </Col>

            </Navbar.Collapse>
          </Row>
        </div>
      </Navbar>
  );
}

export default Header;