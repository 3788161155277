import React, {ChangeEvent, useEffect, useState} from 'react';
import conf from '../config';
import {Button, Col, Row} from "react-bootstrap";
import util from "../util";
import {Trans, useTranslation} from 'react-i18next';
import {AxiosResponse} from "axios";
import {CatalogCodeDto} from './Companies';
import Select from "react-select";
import {Token} from "../types/token";
import {useSelector} from "react-redux";


export interface NewCompanyDto {
  code: string;
  name: string;
  suissetecMember: boolean;
  catalogCodes: string[];
  assemblyHourlyRate: string;
  internalAssemblyHourlyRate: string;
  externalId: string;
  reseller: boolean;
}

export interface NewCompanyProps {
  currentPage: number,
  updateCompanyListElements: (pageNumber: number, queryParam: string) => void,
  catalogCodes: CatalogCodeDto[],
  setCheckedButton: any,
  getCatalogCodesFromCatalogNames: (catalogNames: string[]) => string[],
}

export interface MultiSelectModel {
  value: string,
  label: string,
}

const NewCompany = ({
                      updateCompanyListElements,
                      currentPage,
                      catalogCodes,
                      setCheckedButton,
                      getCatalogCodesFromCatalogNames,
                    }: NewCompanyProps) => {
  const token: Token = useSelector((state: any) => state.addAuthData);
  const [selectedOptions, setSelectedOptions] = useState<MultiSelectModel[]>([]);
  const [options, setOptions] = useState<MultiSelectModel[]>([]);
  let selectedValues: string[] = [];
  const [isSuissetechMember, setIsSuissetechMember] = useState(false);
  const {t} = useTranslation();
  const [company, setCompany] = useState<NewCompanyDto>({
    code: '',
    name: '',
    suissetecMember: false,
    catalogCodes: [],
    assemblyHourlyRate: '65.00',
    internalAssemblyHourlyRate: '60.00',
    externalId: '',
    reseller: false
  });

  useEffect(() => {
    converterCatalogCodesToOptions();

  }, []);

  const converterCatalogCodesToOptions = () => {
    let selectOptionsData: MultiSelectModel[] = [];

    for (let i = 0; i < catalogCodes.length; i++) {
      let catalog = catalogCodes[i];
      selectOptionsData.push({value: catalog.name, label: catalog.name});
    }
    setOptions(selectOptionsData);
  }

  const converterSelectedOptionsInStringArray = (selectedObjects: MultiSelectModel[]) => {
    for (let i = 0; i < selectedObjects.length; i++) {
      let catalogName = selectedObjects[i].value;
      selectedValues.push(catalogName);
    }
    return selectedValues;
  }


  const handleAddCompany = async () => {

    company.assemblyHourlyRate = (Math.round((parseFloat(company.assemblyHourlyRate) + Number.EPSILON) * 100) / 100).toFixed(2);
    company.internalAssemblyHourlyRate = (Math.round((parseFloat(company.internalAssemblyHourlyRate) + Number.EPSILON) * 100) / 100).toFixed(2);

    let catalogNames = converterSelectedOptionsInStringArray(selectedOptions);
    let newCatalogCodes = getCatalogCodesFromCatalogNames(catalogNames);
    company.catalogCodes = newCatalogCodes;

    util.serviceCallWrapper({
          method: 'POST',
          url: conf.urls.serviceUrl + conf.urls.orderOverviewCompanyAPI,
          data: company,
          headers: {Authorization: `Bearer ${token.accessToken}`}
        },
        (result: AxiosResponse) => {
          if (result.status === 200) {
            setCompany({
              code: '',
              name: '',
              suissetecMember: false,
              catalogCodes: [],
              assemblyHourlyRate: '',
              internalAssemblyHourlyRate: '',
              externalId: '',
              reseller: false
            });
          }
          updateCompanyListElements(currentPage, "&status=ACTIVE")
          setSelectedOptions([]);
          setIsSuissetechMember(false);
          setCompany(prevState => ({
            ...prevState,
            ['assemblyHourlyRate']: '65.00',
            ['internalAssemblyHourlyRate']: '60.00',
          }));
          setCheckedButton('ACTIVE');

        },
        {
          200: {
            'SUCCESS': 'Company created with code = ' + company.code + '.'
          },
          500: {
            'ERROR': 'Error occured while creating company with code = ' + company.code + '.'
          },
        },
        () => {
          console.log("Error occurred while creating company");
        },
        true
    );


  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setCompany(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setCompany(prevState => ({
      ...prevState,
      [event.target.name]: event.target.checked
    }))
  }

  const handleRateFloatFormat = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
      setCompany(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  }

  function handleSelect(data: any) {
    setSelectedOptions(data);
  }

  return (
      <div className="newCompany">

        <Row className="tableHeader">
          <Col xs={2} className="label">
            <Trans
                i18nKey={'company.newCompany.newCompany.name.label'}/>
          </Col>
          <Col xs={1} className="label">
            <Trans
                i18nKey={'company.newCompany.newCompany.code.label'}/>
          </Col>
          <Col xs={2} className="label">
            <Trans
                i18nKey={'company.newCompany.newCompany.CatalogCodes.label'}/>
          </Col>
          <Col xs={1} className="label">
            <Trans
                i18nKey={'company.newCompany.newCompany.rate.label'}/>
          </Col>
          <Col xs={1} className="label">
            <Trans
                i18nKey={'company.newCompany.newCompany.internalRate.label'}/>
          </Col>
          <Col xs={1} className="label">
            <Trans
                i18nKey={'company.newCompany.newCompany.suissetecMember.label'}/>
          </Col>
          <Col xs={1} className="label">
            <Trans
                i18nKey={'company.newCompany.newCompany.externalId.label'}/>
          </Col>
          <Col xs={1} className="label">
            <Trans
                i18nKey={'company.newCompany.newCompany.reseller.label'}/>
          </Col>
          <Col xs={2} className="label">
          </Col>
        </Row>

        <Row className="tableContent">
          <Col xs={2} className="value">
            <input className="companyName" name="name"
                   value={company.name}
                   onChange={handleInputChange}
            />
          </Col>
          <Col xs={1} className="value">
            <input className="companyCode w-100" name="code"
                   value={company.code}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={2} className="value">
            <div className="app w-100">
              <Select
                  id='codes'
                  options={options} // should be of MultiSelectModel
                  value={selectedOptions}
                  placeholder={t('company.companyMain.backofficeSelectBox.placeholder')}
                  onChange={handleSelect}
                  isSearchable={true}
                  isMulti
              />
            </div>
          </Col>
          <Col xs={1} className="value">
            <input className="assemblyHourlyRate w-100"
                   name="assemblyHourlyRate"
                   value={company.assemblyHourlyRate}
                   onChange={handleRateFloatFormat}/>
          </Col>
          <Col xs={1} className="value">
            <input className="internalAssemblyHourlyRate w-100"
                   name="internalAssemblyHourlyRate"
                   value={company.internalAssemblyHourlyRate}
                   onChange={handleRateFloatFormat}/>
          </Col>
          <Col xs={1} className="value">
            <input type="checkbox" name="suissetecMember" onChange={handleCheckBox}
                   checked={company.suissetecMember}/>
          </Col>
          <Col xs={1} className="value">
            <input className="companyExternalId w-100" name="externalId"
                   value={company.externalId}
                   onChange={handleInputChange}/>
          </Col>
          <Col xs={1} className="value">
            <input type="checkbox" name="reseller" onChange={handleCheckBox}
                   checked={company.reseller}/>
          </Col>
          <Col xs={1} className="value">
          </Col>
          <Col xs={1} className="value">
            <Button className="function-button addNewCompany" onClick={() => {
              handleAddCompany()
            }}>
              <img src="/common-icons/plus-circle-black.svg"
                   style={{width: 12, height: 12}} alt='Add'/>
              <p><Trans
                  i18nKey={'company.newCompany.newCompany.addNew.value'}/>
              </p>
            </Button>
          </Col>
        </Row>

      </div>
  );
}

export default NewCompany;
