import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import conf from "../config";
import util from "../util";
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {BackofficeVersion} from "../generated/api/ineexaApi";

export interface ExistingOrderOverviewProps {
  order: BackofficeVersion,
}

const ExistingOrderOverview = ({order}: ExistingOrderOverviewProps) => {

  const {t} = useTranslation();
  const history = useHistory();

  const redirectToCustomerChanges = () => {
    history.push(`${conf.urls.backoffice_customer_changes}/${order.id}`);
  };

  const openOrderInFrontend = () => {
    const url = `${window._env_.REACT_APP_FRONTEND_URL}/order/${order.order.id}/v/${order.id}/edit`;
    window.open(url);
  };

  return (
      <div>
        <Row className="tableContent">
          <Col xs={2} className="value">
            <Row>
              {order.user.company.name}
            </Row>
          </Col>
          <Col xs={2} className="value">
            {order.order.name}
          </Col>
          <Col xs={1} className="value">
            {order.pageCount}
          </Col>
          <Col xs={2} className="value">
            {order.productCount}
          </Col>
          <Col xs={2} className="value">
            {util.displayTime(order.modified, conf.formatting.dateTime)}
          </Col>
          <Col xs={1} className="value">
            {order.reviewedCustomerChangesCount} / {order.totalCustomerChangesCount}
          </Col>
          <Col xs={1} className="value">
            <Button className="function-button" onClick={redirectToCustomerChanges}>
              <p>{t('existingOrder.existingOrder.orderModal.value')}</p>
            </Button>
          </Col>
          <Col xs={1} className="value">
            <Button className="function-button" onClick={openOrderInFrontend}>
              <p>{t('existingOrder.existingOrder.open.value')}</p>
            </Button>
          </Col>
        </Row>
      </div>
  );

};

export default ExistingOrderOverview;
